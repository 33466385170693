import React, { Fragment, useState, useEffect } from "react";

import RaceSelector from "../components/RaceSelector/RaceSelector";
import BlockTitle from "../components/Common/BlockTitle";
import { RaceResults } from "../components/RaceResults";

import { useRacesContext } from "../contexts/races";

const ResultsMainPage = () => {
  const [raceId, setRaceId] = useState(null);
  const [selectedRaceType, setSelectedRaceType] = useState({ key: "Race" });
  const { races, loadRace } = useRacesContext();

  useEffect(() => {
    raceId && loadRace(raceId);
  }, [loadRace, raceId]);

  return (
    <Fragment>
      <div style={{ marginTop: 24 }}>
        <BlockTitle>Результати</BlockTitle>
        <RaceSelector
          onRaceChange={setRaceId}
          onRaceTypeChange={setSelectedRaceType}
        />
        {raceId && (
          <RaceResults race={races[raceId]} type={selectedRaceType.key} />
        )}
      </div>
    </Fragment>
  );
};

export default ResultsMainPage;
