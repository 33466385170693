import React from "react";
import styled from "styled-components";
import { renderFounders } from "./FoundersData";
import { renderTeam } from "./TeamData";
import theme from "../../styles/theme";

import AboutUsBackground from "../../assets/AboutUsBackground.png";
import RedBullImage from "../../assets/ir.png";
import F1ManagerImage from "../../assets/f1manager.jpg";

import BlockTitle from "../../components/Common/BlockTitle";

const StyledImgTop = styled.img`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 150px;
  object-fit: cover;
  opacity: 1;
  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  position: absolute;
`;

const Section1 = styled.div`
  color: white;
  font-size: 24px;
  padding-top: 220px;
  position: relative;
`;

const Section2 = styled.div`
  padding-top: 60px;
  position: relative;
`;

const Section3 = styled.div`
  padding-top: 60px;
  position: relative;
`;

const Section1ContextContainer = styled.div`
display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: ${theme.breakpointsCustom.lg}px) {
      flex-direction: column-reverse;
    }
}
`;

const Section2ContextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    flex-direction: column;
  }
`;
const Section2TextContainer = styled.div``;
const Section2TextContainerTitle = styled.div`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
`;
const Section2TextContainerDiscription = styled.div`
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  max-width: 550px;
`;

const StyledImgSection2Container = styled.div``;

const StyledImgSection2 = styled.img`
  max-width: 616px;
  width: 100%;
  opacity: 1;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
`;

const Founders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-row-gap: 50px;
  font-family: "Montserrat", sans-serif;
  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    grid-template-columns: 1fr;
  }
`;

const AboutUsPage = () => {
  return (
    <div>
      <ImageContainer>
        <Title>Про Нас</Title>
        <StyledImgTop src={AboutUsBackground} />
      </ImageContainer>

      <Section1>
        <Section1ContextContainer>
          <StyledImgSection2Container>
            <StyledImgSection2 src={RedBullImage} />
          </StyledImgSection2Container>
          <Section2TextContainer>
            <Section2TextContainerDiscription>
              <p>
                Slick Racing Ukraine – спільнота любителів віртуального
                автоспорту створена у 2022 році. Під супроводом невеликої
                команди ентузіастів було проведено уже 7 національних турнірів у
                серії F1. Наші заходи створюють унікальний досвід як для
                учасників, так і для вболівальників. Кожен турнір вирізняється
                ретельною організацією, де змагальний дух та високий рівень
                суперництва створюють справжню напругу на трасі. Тут гонщики
                отримують можливість продемонструвати свої навички у запеклій
                боротьбі, де першість здобувається завдяки майстерності та
                стратегії.
              </p>
            </Section2TextContainerDiscription>
          </Section2TextContainer>
        </Section1ContextContainer>
      </Section1>

      <Section2>
        <Section2ContextContainer>
          <Section2TextContainer>
            <Section2TextContainerTitle>
              Більше, ніж просто перегони
            </Section2TextContainerTitle>
            <Section2TextContainerDiscription>
              <p>
                Slick Racing об'єднує людей, які поділяють спільну пристрасть до
                автоспорту і віртуальних перегонів. Ми цінуємо кожного члена
                нашого онлайн-ком'юніті та прагнемо створити для всіх комфортну
                та затишну атмосферу.
              </p>
            </Section2TextContainerDiscription>
          </Section2TextContainer>

          <StyledImgSection2Container>
            <StyledImgSection2 src={F1ManagerImage} />
          </StyledImgSection2Container>
        </Section2ContextContainer>
      </Section2>

      <Section3>
        <BlockTitle>Засновники</BlockTitle>
        <Founders>{renderFounders()}</Founders>
      </Section3>
      <Section3>
        <BlockTitle>Команда</BlockTitle>
        <Founders>{renderTeam()}</Founders>
      </Section3>
    </div>
  );
};

export default AboutUsPage;
