import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Drivers from "../../components/Drivers/Drivers"; // Виправив шлях на правильний
import BlockTitle from "../../components/Common/BlockTitle"; // Виправив шлях на правильний
import { useDriversContext } from "../../contexts/drivers"; // змінив відносний шлях
import IracingLogo from "../../assets/IracingLogo.jpg"; // змінив відносний шлях
import F1Logo from "../../assets/F1iconsquare.png"; 
import styled, { css } from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const DriversPage = () => {
  const { loadDrivers } = useDriversContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSimulator = searchParams.get("simulator") || "EA F1";
  const [simulator, setSimulator] = useState(initialSimulator);

  const handleSimulatorChange = (sim) => {
    if (simulator === sim) return;
    setSimulator(sim);
    setSearchParams({ simulator: sim });
  };

  const GameSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  `;

  const SimButton = styled.button`
    background: none;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    gap: 4px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 8px;

    ${(props) =>
      props.active &&
      css`
        border: 1px solid #aeff00;
        background: #aeff0017;
        border-radius: 4px;
      `}
    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
      `}
  `;

  const SimulatorImg = styled.img`
    object-fit: cover;
    width: 16px;
    height: 16px;
  `;

  const TooltipContainer = styled.div`
    display: flex;
    gap: 4px;
    & > span {
      color: white;
    }
  `;

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  useEffect(() => {
    loadDrivers(simulator);
  }, [simulator, loadDrivers]);

  return (
    <div style={{ marginTop: 24 }}>
      <BlockTitle>
        Пілоти
        <GameSelectorContainer>
          <NoMaxWidthTooltip
            title={
              <TooltipContainer>
                <span>Незабаром</span>
              </TooltipContainer>
            }
            arrow
            placement="left"
          >
            <SimButton
              onClick={() => handleSimulatorChange("iRacing")}
              active={simulator === "iRacing"}
              disabled
            >
              <SimulatorImg src={IracingLogo} />
              iRacing
            </SimButton>
          </NoMaxWidthTooltip>
          <SimButton
            onClick={() => handleSimulatorChange("EA F1")}
            active={simulator === "EA F1"}
          >
            <SimulatorImg src={F1Logo} />
            EA F1
          </SimButton>
        </GameSelectorContainer>
      </BlockTitle>
      <Drivers simulator={simulator} />
    </div>
  );
};

export default DriversPage;
