import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;sans-serif;
`;

export default StyledLink;
