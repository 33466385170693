import * as vars from "./variables";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: vars.colors.dark,
      light: vars.colors.darkLight,
    },
    secondary: {
      main: vars.colors.green,
      contrastText: vars.colors.dark,
    },
    text: {
      primary: vars.colors.white,
      secondary: vars.colors.grey,
    },
    mode: "dark",
  },
  ...vars,
});

export default theme;
