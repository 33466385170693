import moment from "moment";

import gapi, { loadClient, loaded } from "./client";

export const getPlaylistVideos = async ({ playlistId }) => {
  if (!loaded) await loadClient();
  const { result } = await gapi.client.youtube.playlistItems.list({
    maxResults: 50,
    part: ["snippet"],
    playlistId,
  });

  const videos = result.items.map((item) => {
    return {
      id: item.id,
      title: item.snippet.title,
      image: item.snippet.thumbnails.standard.url,
      date: moment(item.snippet.publishedAt).format("DD/MM/YY"),
      link: `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}&list=${playlistId}`,
    };
  });

  return videos;
};

export const loadVideosByIds = async (videoIds) => {
  if (!loaded) await loadClient();

  const { result } = await gapi.client.youtube.videos.list({
    part: ["snippet,contentDetails"],
    id: videoIds,
  });

  const videos = result.items.map((item) => {
    return {
      id: item.id,
      title: item.snippet.title,
      image: item.snippet.thumbnails.standard.url,
      date: moment(item.snippet.publishedAt).format("DD/MM/YY"),
      link: `https://www.youtube.com/watch?v=${item.id}`,
    };
  });

  return videos;
};

export const loadStreams = async () => {
  if (!loaded) await loadClient();

  const { result } = await gapi.client.youtube.search.list({
    part: "snippet",
    channelId: "UCOAanTZR56k1Y1QnZ8KTHXA",
    type: "video",
    eventType: "completed", // Use "live", "upcoming", or "completed" for specific filtering
    maxResults: 50, // Maximum results per page
    order: "date",
  });

  const videos = result.items.map((item) => {
    return {
      id: item.id.videoId,
      title: item.snippet.title,
      image: item.snippet.thumbnails.high.url,
      date: moment(item.snippet.publishedAt).format("DD/MM/YY"),
      link: `https://www.youtube.com/watch?v=${item.id.videoId}`,
    };
  });

  return videos;
};
