import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import theme from "../../styles/theme";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";

import { default as driver1 } from "../../assets/rewards/reward-driver-1.png";
import { default as driver2 } from "../../assets/rewards/reward-driver-2.png";
import { default as driver3 } from "../../assets/rewards/reward-driver-3.png";
import { default as team1 } from "../../assets/rewards/reward-team-1.png";
import { default as team2 } from "../../assets/rewards/reward-team-2.png";
import { default as team3 } from "../../assets/rewards/reward-team-3.png";

const StyledTableCell = muiStyled(TableCell)`
  color: #67676d;
  font-size: 10px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid ${theme.colors.secondaryDark};
  padding: 0 8px;
  height: 15px;
`;

const StyledTableCellRow = muiStyled(TableCell)`
  color: white;
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid ${theme.colors.secondaryDark};
  padding: 0 8px;
  height: 40px;
`;

const CenteredImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 20px;
`;

const TableTitle = styled.div`
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 12px 0px;
`;

const TeamContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  position: relative;
  padding-bottom: ${({ isLast }) => (isLast ? "0px" : "12px")};
  white-space: nowrap;
`;

const LineConnector = styled.div`
  position: absolute;
  left: -13.5px;
  top: -8px;
  bottom: 7px;
  width: 10px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
`;

const LogoImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0px;
  justify-content: center;
`;

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const renderChampionshipResult = (result, type) => {
  let image = null;
  let imageName = "";

  if (result === 1) {
    image = type === "driver" ? driver1 : team1;
    imageName = "Чемпіон";
  } else if (result === 2) {
    image = type === "driver" ? driver2 : team2;
    imageName = "Друге місце";
  } else if (result === 3) {
    image = type === "driver" ? driver3 : team3;
    imageName = "Третє місце";
  }

  if (image) {
    return (
      <Tooltip title={imageName} arrow placement="top">
        <CenteredImageContainer>
          <StyledImage src={image} alt={`${result} place`} />
        </CenteredImageContainer>
      </Tooltip>
    );
  } else {
    if (result == null) {
      return "";
    }
    return `${result}-й`;
  }
};

const groupBySeason = (seasonsArray) => {
  const groupedSeasons = {};

  seasonsArray.forEach((season) => {
    const seasonId = season.season.id;

    if (!groupedSeasons[seasonId]) {
      groupedSeasons[seasonId] = {
        ...season.season,
        teams: [],
        driverResults: {
          races: 0,
          points: 0,
          driverChampionshipResult: null,
          constructorChampionshipResult: null,
        },
      };
    }

    groupedSeasons[seasonId].teams.push({
      name: season.team?.name || "Резервний пілот",
      color: season.team?.color,
      logoImage: season.team?.logoImage,
      races: season.driverResults.races,
      points: season.driverResults.points,
      driverChampionshipResult: season.driverResults.driverChampionshipResult,
      constructorChampionshipResult:
        season.driverResults.constructorChampionshipResult,
    });

    groupedSeasons[seasonId].driverResults.races += season.driverResults.races;
    groupedSeasons[seasonId].driverResults.points +=
      season.driverResults.points;

    if (
      season.driverResults.driverChampionshipResult &&
      (!groupedSeasons[seasonId].driverResults.driverChampionshipResult ||
        season.driverResults.driverChampionshipResult <
          groupedSeasons[seasonId].driverResults.driverChampionshipResult)
    ) {
      groupedSeasons[seasonId].driverResults.driverChampionshipResult =
        season.driverResults.driverChampionshipResult;
    }

    if (
      season.driverResults.constructorChampionshipResult &&
      (!groupedSeasons[seasonId].driverResults.constructorChampionshipResult ||
        season.driverResults.constructorChampionshipResult <
          groupedSeasons[seasonId].driverResults.constructorChampionshipResult)
    ) {
      groupedSeasons[seasonId].driverResults.constructorChampionshipResult =
        season.driverResults.constructorChampionshipResult;
    }
  });

  return Object.values(groupedSeasons);
};

const SeasonsTable = ({ seasons, team }) => {
  const seasonsArray = Array.isArray(seasons)
    ? seasons
    : Object.values(seasons || {});

  const groupedSeasons = groupBySeason(seasonsArray).reverse();

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        background: "#2a2a2ac4",
        padding: "12px",
        border: "2px solid #393B40",
        whiteSpace: "nowrap",
      }}
    >
      <TableTitle>Slick Racing Кар’єра</TableTitle>
      <Table sx={{ minWidth: 650 }} aria-label="seasons table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{ width: "200px" }}>
              Сезон
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ width: "200px" }}>
              Симулятор
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ width: "200px" }}>
              Команди
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              Гонки
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              Очки
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              Особистий залік
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              Кубок Конструкторів
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedSeasons.length > 0 ? (
            groupedSeasons.map((season, index) => (
              <TableRow key={index}>
                <StyledTableCellRow align="left">
                  {season.shortName}
                </StyledTableCellRow>
                <StyledTableCellRow align="left">
                  {season.simulator}
                </StyledTableCellRow>
                <StyledTableCellRow align="left">
                  <ColumnContainer>
                    {season.teams.map((team, i) => (
                      <TeamContainer
                        key={i}
                        marginLeft={`${i * 20}px`}
                        isLast={i === season.teams.length - 1}
                      >
                        {i !== 0 && <LineConnector />}
                        {team && team.logoImage && (
                          <LogoImage src={team.logoImage} alt={team.name} />
                        )}
                        {team.name}
                      </TeamContainer>
                    ))}
                  </ColumnContainer>
                </StyledTableCellRow>
                <StyledTableCellRow
                  sx={{
                    color: `${team.color ? team.color : theme.colors.green}`,
                  }}
                  align="center"
                >
                  <PointsContainer>{season.teams[0]?.races}</PointsContainer>
                </StyledTableCellRow>
                <StyledTableCellRow
                  sx={{
                    color: `${team.color ? team.color : theme.colors.green}`,
                  }}
                  align="center"
                >
                  <PointsContainer>{season.teams[0]?.points}</PointsContainer>
                </StyledTableCellRow>
                <StyledTableCellRow align="center">
                  <PointsContainer>
                    {season.teams.length > 0 && (
                      <ResultContainer>
                        {renderChampionshipResult(
                          season.teams[0].driverChampionshipResult,
                          "driver"
                        )}
                      </ResultContainer>
                    )}
                  </PointsContainer>
                </StyledTableCellRow>
                <StyledTableCellRow align="center">
                  <PointsContainer>
                    {season.teams.map((team, i) => (
                      <ResultContainer key={i}>
                        {renderChampionshipResult(
                          team.constructorChampionshipResult,
                          "constructor"
                        )}
                      </ResultContainer>
                    ))}
                  </PointsContainer>
                </StyledTableCellRow>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <StyledTableCellRow colSpan={7} align="center">
                No data
              </StyledTableCellRow>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SeasonsTable;
