export default function msToTime(duration) {
  var milliseconds = parseInt(duration % 1000),
    seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60);

  var minutesStr = minutes === 0 ? "" : minutes.toString();
  var secondsStr = seconds < 10 ? seconds : seconds.toString();
  var checkSeconds = seconds < 10 ? "0" + seconds : seconds.toString();
  var millisecondsStr =
    milliseconds < 10
      ? "00" + milliseconds
      : milliseconds < 100
      ? "0" + milliseconds
      : milliseconds;

  return (
    (minutes === 0 ? "" : minutesStr + ":") +
    (minutesStr ? checkSeconds + "." : secondsStr + ".") +
    millisecondsStr
  );
}
