import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import theme from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { isDark } from "../../utils/helpers/colors";

const TeamName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #67676d;
`;

const DriverName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Position = styled.div`
  width: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Arrow = styled(ChevronRightIcon)`
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const StandingItemContainerSkeleton = styled.div`
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  background-color: ${theme.colors.darkLight};
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  @media (max-width: ${theme.breakpointsCustom.md}px) {
    max-width: 500px;
  }
`;

const getOppositeColor = (color, defaultColor) => {
  if (!color) color = defaultColor;
  return isDark(color) ? theme.colors.white : theme.colors.darkLight;
};

const StandingItemContainer = styled.div`
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  // background-color: ${theme.colors.darkLight};
  border-radius: 4px;
  padding: 8px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease-in-out;
  user-select: none;
  cursor: pointer;

  background: linear-gradient(
      to left,
      ${theme.colors.darkLight} 50%,
      ${(props) => props.color || theme.colors.white} 50%
    )
    right;
  background-size: 200% 100%;

  &:hover {
    background-position: left;

    & ${DriverName} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }

    & ${Position} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }

    & ${TeamName} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }

    & ${Arrow} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }
  }
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    max-width: 500px;
  }
`;

const Side = styled.div`
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ColorBlock = styled.div`
  width: 4px;
  height: 16px;
  background-color: ${(props) => props.color};

  @media (max-width: 480px) {
    height: 40px;
  }
`;

const Points = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.dark};
  padding: 4px 16px;
  border-radius: 32px;
  font-weight: 600;
  width: 130px;
  text-align: center;
`;

const TeamDrivers = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: flex-start;
  align-items: flex-end;

  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 2px;
  }
`;

export const DriverStandingsItem = ({ driver, points, position }) => {
  // const link = "/";
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/drivers/${driver.id}`);
  };
  return (
    //<Link to={link} style={{ color: "inherit", textDecoration: "none" }}>
    <StandingItemContainer color={driver.team?.color} onClick={handleClick}>
      <Side>
        <Position>{position}</Position>
        <ColorBlock color={driver.team?.color} />
        <TeamDrivers>
          <DriverName> {`${driver.firstName} ${driver.lastName}`}</DriverName>
          <TeamName> {driver.team?.name}</TeamName>
        </TeamDrivers>
      </Side>

      <Side>
        <Points>{`${points} ОЧ.`}</Points>
        <Arrow sx={{ color: theme.colors.white }} />
      </Side>
    </StandingItemContainer>
    //   </Link>
  );
};

export const DriverStandingsItemSkeleton = () => {
  return (
    <StandingItemContainerSkeleton>
      <Skeleton
        variant="rectangular"
        height="100%"
        width="15%"
        style={{ borderRadius: "12px" }}
      />
      <Skeleton
        variant="rectangular"
        height="100%"
        width="10%"
        style={{ borderRadius: "12px" }}
      />
    </StandingItemContainerSkeleton>
  );
};
