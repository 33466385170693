import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import moment from "moment";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import theme from "../../styles/theme";
import Link from "../../components/Common/Link";
import { Post } from "../../components/News/Post";
import {
  getRelationItem,
  getPostImageUrl,
  POST_IMAGE_SIZES,
} from "../../utils/helpers/generic";
import { useNewsContext } from "../../contexts/news";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const PostTitleLatest = styled.div`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  font-weight: 400;
`;

const LatestNewsContainer = styled.div`
  margin-left: 16px;
  margin-top: 220px;
  height: max-content;
  background: ${theme.colors.darkLight}50;
  padding: 16px;
  border-radius: 4px;
  max-width: 400px;
  position: sticky;
  width: 100%;
  top: 80px;
  flex-direction: column;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  border-left: 3px solid #2a2a2a;
  // padding-left: 16px;

  @media (max-width: ${theme.breakpointsCustom.max}px) {
    display: none;
  }
`;

const LatestNewsTitle = styled.div`
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
`;

const LatestNewsColumn = styled(Link)`
  display: flex;
  padding: 8px 0px;
  gap: 12px;
  border-bottom: 2px solid ${theme.colors.darkLight};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-bottom: 2px solid ${theme.colors.green};
  }
`;

const StyledImgLatest = styled.img`
  height: fit-content;
  width: 150px;
  border-radius: 4px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

const StyledLinkLatestNews = styled(Link)`
  margin-bottom: 16px;
  text-decoration: none;
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  margin-top: 16px;
  transition: all 0.3s;

  &:hover {
    color: ${theme.colors.green};
  }
`;

const NewType = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: ${(props) => props.color};
`;

const NewTime = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  opacity: 0.5;
  color: ${theme.colors.white};
`;

const LatestNewsPostTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LatestNews = () => {
  const { id } = useParams();
  const { posts } = useNewsContext();
  if (!posts?.length) return;

  return (
    <LatestNewsContainer>
      <LatestNewsTitle>Останні публікації</LatestNewsTitle>
      {posts
        .slice(0, 5)
        .filter((post) => post.id !== Number(id))
        .slice(0, 4)
        .map((post, i) => {
          const imageUrl = getPostImageUrl(post, POST_IMAGE_SIZES.small);
          const category = getRelationItem(post, "category");
          const time = moment(post.publishedAt).fromNow();
          let { title } = post;
          if (title.length > 60) {
            title =
              title.slice(0, 60).split(" ").slice(0, -1).join(" ") + "...";
          }
          return (
            <LatestNewsColumn key={i} to={`/news/${post.id}`}>
              <StyledImgLatest src={imageUrl} />
              <LatestNewsPostTitleContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "4px",
                  }}
                >
                  <NewType color={category.color}>{category.name}</NewType>
                  <NewTime>{time}</NewTime>
                </div>

                <PostTitleLatest>{title}</PostTitleLatest>
              </LatestNewsPostTitleContainer>
            </LatestNewsColumn>
          );
        })}
      <StyledLinkLatestNews to="/news">
        Усі публікації <ChevronRightIcon />
      </StyledLinkLatestNews>
    </LatestNewsContainer>
  );
};

export const NewsPostPage = () => {
  const { id } = useParams();
  const { posts, postsMap, loadPost, loadPosts } = useNewsContext();
  const post = useMemo(() => postsMap[id], [id, postsMap]);

  useEffect(() => {
    loadPosts(id);

    if (!posts.length) {
      loadPosts({});
    }
  }, [id]); // eslint-disable-line

  useEffect(() => {
    loadPost(id);
  }, [id]); // eslint-disable-line

  useEffect(
    () => () => {
      window.document.title = `Slick Racing Ukraine`;
    },
    []
  );

  if (!post) {
    // TODO: add skeleton
    return (
      <div style={{ display: "flex", columnGap: "16px" }}>post not found</div>
    );
  }

  window.document.title = `${post.title} | Slick Racing Ukraine`;

  return (
    <div style={{ marginTop: 24 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ underline: "hover", color: "inherit" }} to="/">
          Головна
        </Link>
        <Link style={{ underline: "hover", color: "inherit" }} to="/news">
          Новини
        </Link>
        <Typography sx={{ color: "#c1c1c1", cursor: "default" }}>
          {post.title}
        </Typography>
      </Breadcrumbs>
      <div style={{ display: "flex", overflow: "visible" }}>
        <div
          style={{
            maxWidth: "900px",
            marginTop: "24px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Post {...post} />
        </div>
        <LatestNews />
      </div>
    </div>
  );
};

export default NewsPostPage;
