import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import noneDriver from "../../assets/none_driver.png";
import DriverAchievements from "./DriverTrophy";

const DriverBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-top: 29px;
  max-width: 100%;
  z-index: 2;
  gap: 10px;
  height: 84px;
`;

const DriverName = styled.div`
  font-size: 24px;
  font-weight: 600;
  transition: color 0.3s ease;
  // text-wrap: nowrap;
`;

const DriverTeam = styled.div`
  color: #6cd3bf;
  transition: color 0.3s ease;
`;
const DriverNumber = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #67676d70;
  font-size: 36px;
  font-weight: 600;
  transition: color 0.3s ease;
`;

const StyledImg = styled.img`
  transition: transform 0.5s;
  z-index: 1;
  object-fit: cover;
`;

const DriverTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
`;

const DriverGlobalStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DriverGlobalStatsTitle = styled.div`
  display: flex;
  gap: 8px;
  text-align: end;
  font-size: 16px;
  font-weight: 700;
  transition: color 0.3s ease;
`;
const DriverGlobalStatsResults = styled.div`
  display: flex;
  gap: 8px;
  text-align: end;
  color: ${theme.colors.green};
  font-size: 16px;
  font-weight: 700;
  transition: color 0.3s ease;
`;

const Border = styled.div`
  border-top: 4px solid ${theme.colors.green};
  background: ${theme.colors.green};
  max-width: 350px;
  height: 0px;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 61.7%;
`;

const DriverContainer = styled.div`
  background-color: ${theme.colors.darkLight};
  width: 100%;
  padding: 25px 20px 25px 20px;
  position: relative;
  user-select: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;sans-serif;
  cursor: pointer;

  &:hover ${DriverName}, &:hover ${DriverTeam} {
    color: ${theme.colors.darkLight};
  }
  &:hover ${StyledImg} {
    transform: scale(1.05);
  }
  &:hover ${DriverGlobalStatsResults} {
    color: ${theme.colors.dark};
  }
  &:hover ${DriverGlobalStatsTitle} {
    color: ${theme.colors.dark};
  }
  &:hover ${Border} {
    border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
    max-width: 100%;
    height: 38.3%;
    transition: max-width 0.1s ease, height 0.1s ease 0.1s;
  }
  &:not(:hover) ${Border} {
    height: 0px;
    max-width: 90%;
    transition: height 0.2s ease 0s, max-width 0.2s ease 0.2s;
  }
`;

const DriverInfo = styled.div``;

export const Driver = ({
  id,
  firstName,
  lastName,
  number,
  image,
  globalPoints,
  grandPrixCount,
  driversAchievements,
  constructorAchievements,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/drivers/${id}`);
  };

  return (
    <DriverContainer onClick={handleClick}>
      <DriverTopContainer>
        <StyledImg
          img
          src={image || noneDriver}
          style={{ width: "140px", height: "150px" }}
        />
        <DriverAchievements
          driversAchievements={driversAchievements}
          constructorAchievements={constructorAchievements}
        />
        <DriverNumber>{number || ""}</DriverNumber>
      </DriverTopContainer>
      <Border />
      <DriverBottomContainer>
        <DriverInfo>
          <DriverName>
            {firstName} {lastName}
          </DriverName>
        </DriverInfo>
        <DriverGlobalStatsContainer>
          <DriverGlobalStatsTitle>
            Очки
            <br />
            Гонки
          </DriverGlobalStatsTitle>
          <DriverGlobalStatsResults>
            {globalPoints}
            <br />
            {grandPrixCount}
          </DriverGlobalStatsResults>
        </DriverGlobalStatsContainer>
      </DriverBottomContainer>
    </DriverContainer>
  );
};

export const DriverSkeleton = () => {
  return (
    <DriverContainer style={{ maxHeight: "274px" }}>
      <DriverTopContainer style={{ height: "150px" }}>
        <Skeleton
          style={{
            borderRadius: "15px",
            width: "130px",
            height: "150px",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        />
        <Skeleton
          style={{
            borderRadius: "15px",
            width: "90px",
            height: "100px",
            marginTop: "65px",
            marginLeft: "20px",
          }}
        />
      </DriverTopContainer>
      <Skeleton height="12px" width="100%" style={{ borderRadius: "0px" }} />
      <DriverBottomContainer style={{ paddingTop: "0px", marginLeft: "10px" }}>
        <DriverInfo>
          <DriverName>
            <Skeleton
              height="70px"
              width="150px"
              style={{ borderRadius: "12px" }}
            />
          </DriverName>
        </DriverInfo>
        <DriverNumber>
          <Skeleton
            height="80px"
            width="36px"
            style={{ borderRadius: "12px" }}
          />
        </DriverNumber>
      </DriverBottomContainer>
    </DriverContainer>
  );
};
