import React from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import moment from "moment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Link from "../Common/Link";
import theme from "../../styles/theme";
import {
  getRelationItem,
  getPostImageUrl,
  getPostLink,
  POST_IMAGE_SIZES,
} from "../../utils/helpers/generic";

const Title = styled.div`
  font-size: 18px;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;

  &.main {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;sans-serif;
    font-weight: 400;
    line-height: 24px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  text-wrap: balance;
  transition: all 0.3s;

  &.main {
    padding: 20px 16px;
    line-height: 34px;
  }

  &.main-skeleton{
    left:16px;
    right: 0;
    bottom: 16px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
  transition: all 0.2s;
`;

const DarkBackground = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  background: linear-gradient(
    8deg,
    rgba(39, 38, 42, 1) 0%,
    rgba(39, 38, 42, 0.6) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  transition: all 0.2s;
`;

const ReadButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -50px;
  transition: all 0.3s;
`;

const ReadButton = styled.div`
  color: ${theme.colors.green};
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;
  z-index: 3;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
`;

const NewsType = styled.div`
  font-family: "Montserrat", sans-serif;sans-serif;
  font-size: 14px;
  color: ${(props) => props.color};
`;

const NewsDate = styled.div`
  font-family: "Montserrat", sans-serif;sans-serif;
  font-size: 12px;
  color: ${theme.colors.white};
  opacity: 70%;
`;

const Post = styled(Link)`
  position: relative;
  height: 100%;
  z-index: 3;
  border-radius: 4px;
  width: 100%;
  box-shadow: #00000040 0px 2px 8px 0px;
  color: ${theme.colors.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${theme.colors.darkLight};
  cursor: pointer;
  transition: all 0.3s;

  @media (min-width: ${theme.breakpointsCustom.lg}px) {
    &.main:hover ${TitleContainer} {
      transform: translateY(-40px);
    }

    &.main ${DarkBackground} {
      height: 135%;
    }

    &.main:hover ${DarkBackground} {
      transform: translateY(-40px);
    }

    &.main:hover ${ReadButtonContainer} {
      bottom: 5px;
    }

    &.secondary:hover ${Image} {
      transform: scale(1.02);
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

export const PostItem = (post) => {
  const category = getRelationItem(post, "category");
  const time = moment(post.publishedAt).format("DD/MM/YYYY");
  return (
    <Post to={getPostLink(post)} className="main">
      <ImageContainer>
        <Image src={getPostImageUrl(post, POST_IMAGE_SIZES.medium)} />
        <DarkBackground />
      </ImageContainer>
      <TitleContainer className="main">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NewsType color={category.color}>{category.name}</NewsType>
          <NewsDate>{time}</NewsDate>
        </div>
        <Title className="main">{post.title} </Title>
      </TitleContainer>
      <ReadButtonContainer>
        <ReadButton>
          читати більше
          <KeyboardArrowRightIcon sx={{ fontSize: "28px" }} />
        </ReadButton>
      </ReadButtonContainer>
    </Post>
  );
};

// export const SecondaryPost = ({ link, image, title, type }) => {
//   return (
//     <Post to={link} className="secondary">
//       <ImageContainer>
//         <Image src={image} />
//         <DarkBackground />
//       </ImageContainer>
//       <TitleContainer className="secondary">
//         <NewsType className="secondary">{type}</NewsType>
//         <Title className="secondary">{title}</Title>
//       </TitleContainer>
//     </Post>
//   );
// };

export const MainPostSkeleton = () => {
  return (
    <Post className="main-skeleton">
      <ImageContainer>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          style={{ borderRadius: "0px" }}
        />
        <DarkBackground />
      </ImageContainer>
      <TitleContainer className="main-skeleton">
        <NewsType>
          <Skeleton height="24px" width="12%" />
        </NewsType>

        <Title className="main">
          <Skeleton height="44px" width="80%" />
          <Skeleton height="44px" width="75%" />
        </Title>
      </TitleContainer>
    </Post>
  );
};

// export const SecondaryPostSkeleton = () => {
//   return (
//     <Post to="#" className="secondary">
//       <ImageContainer>
//         <Skeleton
//           variant="rectangular"
//           height="100%"
//           width="100%"
//           style={{ borderRadius: "0px" }}
//         />
//         <DarkBackground />
//       </ImageContainer>
//       <TitleContainer className="secondary-skeleton">
//         <NewsType>
//           <Skeleton height="18px" width="16%" />
//         </NewsType>

//         <Title className="secondary">
//           <Skeleton height="24px" width="80%" />
//           <Skeleton height="24px" width="70%" />
//         </Title>
//       </TitleContainer>
//     </Post>
//   );
// };
