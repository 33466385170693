import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import theme from "../../styles/theme";
import BlockTitle from "../../components/Common/BlockTitle";
import { loadVideosByIds } from "../../utils/googleApi/youtube";

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s linear;

  @media (max-width: 580px) {
    grid-template-columns: 1fr 1fr 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoPost = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: #00000040 0px 2px 8px 0px;
  color: ${theme.colors.white};
  flex: 1;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${theme.colors.darkLight};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondaryDark};
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const TitleContainer = styled.div`
  padding: 20px 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;

const Date = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  opacity: 0.5;
`;

const Block = styled.div`
  width: 100%;
  z-index: 3;
  margin: 30px 0;
  max-width: 1320px;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const VideoPostComponent = ({ link, image, title, date }) => {
  return (
    <StyledLink to={link} target="_blank" rel="noopener noreferrer">
      <VideoPost>
        <VideoContainer>
          <VideoImage src={image} />
        </VideoContainer>
        <TitleContainer>
          <Date>{date}</Date>
          <Title>{title}</Title>
        </TitleContainer>
      </VideoPost>
    </StyledLink>
  );
};

const VideoPostSkeleton = () => {
  return (
    <VideoPost>
      <VideoContainer>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </VideoContainer>
      <TitleContainer>
        <Skeleton width="60%" />
        <Skeleton />
      </TitleContainer>
    </VideoPost>
  );
};
const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const loadConversations = async () => {
    const videos = await loadVideosByIds([
      "Lxzh7mDZi04",
      "_OzwnG9KT24",
      // "0ZG7BD42xmk",
      // "XPOwYHHExGI",
      "cI_TsUqzDRU",
    ]);

    setConversations(videos);
  };

  useEffect(() => {
    loadConversations();
  }, []);

  return (
    <Fragment>
      <MainContainer>
        <Block>
          <BlockTitle>Інтерв'ю</BlockTitle>
          <PostsContainer>
            {conversations.length
              ? conversations.map((item, key) => (
                  <VideoPostComponent {...item} />
                ))
              : [1, 2, 3].map((item) => <VideoPostSkeleton />)}
          </PostsContainer>
        </Block>
      </MainContainer>
    </Fragment>
  );
};

export default Conversations;
