import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import Button from "@mui/material/Button";

import theme from "../../styles/theme";
import {
  DriverStandingsItem,
  DriverStandingsItemSkeleton,
} from "./DriverStandingsItem";

const StandingsContainer = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  margin: auto !important;
  font-family: "Roboto Condensed" !important;
  font-weight: bold !important;
  width: 200px;

  &:hover {
    background-color: ${theme.colors.white}20 !important;
  }
`;

const DriverStandings = ({ drivers }) => {
  const [all, setAll] = useState(false);

  return (
    <StandingsContainer>
      {!drivers && _.times(10, (i) => <DriverStandingsItemSkeleton key={i} />)}
      {drivers &&
        drivers
          .slice(0, all ? 50 : 10)
          .map((item, i) => <DriverStandingsItem key={i} {...item} />)}

      {drivers && !all && (
        <div style={{ display: "flex", marginTop: 8 }}>
          <StyledButton
            size="small"
            sx={{
              color: theme.colors.white,
            }}
            onClick={() => setAll(!all)}
          >
            Переглянути всю таблицю
          </StyledButton>
        </div>
      )}
    </StandingsContainer>
  );
};

export default DriverStandings;
