import React, { Fragment, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import Selector from "./Selector";
import { useRacesContext } from "../../contexts/races";
import { useScheduleContext } from "../../contexts/schedule";
import theme from "../../styles/theme";
import config from "../../config";

const SelectorContainer = styled.div`
  min-width: 150px;
  flex: 1;

  border-right: 1px solid ${theme.colors.darkLight};
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.darkLight};
  padding-bottom: 16px;
  margin-bottom: 16px;

  > ${SelectorContainer} {
    &:last-child {
      border-right: none;
      border-bottom: none;
    }
  }

  @media screen and (max-width: ${theme.breakpointsCustom.sm}px) {
    flex-direction: column;
    gap: 16px;

    > ${SelectorContainer} {
      & {
        border-right: none;
        border-bottom: 1px solid ${theme.colors.darkLight};
      }
    }
  }
`;
const TitleSelectorContainer = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;sans-serif; sans-serif;
`;

const RaceTitleContainer = styled.div`
  margin: auto;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: ${theme.breakpointsCustom.sm}px) {
    flex-direction: column;
  }
`;

const DashElement = styled(TitleSelectorContainer)`
  &:after {
    content: "-";
  }

  margin: 0px 6px;

  @media screen and (max-width: ${theme.breakpointsCustom.sm}px) {
    display: none;
  }
`;

const defaultOptions = [
  { title: "Кваліфікація", key: "Qualification", id: 1 },
  { title: "Гонка", key: "Race", id: 3 },
];

const sprintOptions = [
  { title: "Кваліфікація", key: "Qualification", id: 1 },
  { title: "Спринт", key: "Sprint", id: 2 },
  { title: "Гонка", key: "Race", id: 3 },
];

const raceTypes = {
  Qualification: "qualiResults",
  Sprint: "sprintResults",
  Race: "raceResults",
};

const hasResultsByType = (race, type) => {
  return race && !!race[raceTypes[type]];
};

const RaceSelector = ({ onRaceChange, onRaceTypeChange }) => {
  const seasonIds = [1, 2, 3, 4, 5, 6, 7];
  const lastSeason = config.constants.lastSeason;

  let [searchParams, setSearchParams] = useSearchParams();
  const { seasons, loadSchedule } = useScheduleContext();
  const { races, loadRace } = useRacesContext();

  const [selectedSeasonId, setSelectedSeasonId] = useState(null);
  const [selectedRaceId, setSelectedRaceId] = useState(null);
  const [selectedRaceType, setSelectedRaceType] = useState(
    defaultOptions[defaultOptions.length - 1]
  );

  const [raceTypeOptions, setRaceTypeOptions] = useState([]);

  useEffect(() => {
    selectedRaceId && loadRace(selectedRaceId);
    // eslint-disable-next-line
  }, [selectedRaceId]);

  const seasonRace = useMemo(() => {
    return seasons[selectedSeasonId]?.races.find(
      (item) => item.id === selectedRaceId
    );
  }, [seasons, selectedSeasonId, selectedRaceId]);

  useEffect(() => {
    if (_.isNull(selectedSeasonId)) {
      const raceId = Number(searchParams.get("r"));
      const seasonId = Number(searchParams.get("s"));

      if (raceId && seasonId) {
        setSelectedRaceId(raceId);
        setSelectedSeasonId(seasonId);
        return;
      }

      setSelectedSeasonId(seasonIds[seasonIds.length - 1]);
      return;
    }

    if (!seasons[selectedSeasonId]) return;

    const selectedRace = seasons[selectedSeasonId]?.races.find(
      (item) => item.id === selectedRaceId
    );

    if (selectedRace) return;

    if (selectedSeasonId === lastSeason) {
      const lastFinishedRace = _.findLast(
        seasons[selectedSeasonId]?.races,
        (race) => race.hasResults
      );

      setSelectedRaceId(lastFinishedRace?.id);
    } else {
      setSelectedRaceId(seasons[selectedSeasonId]?.races[0]?.id);
    }
    // eslint-disable-next-line
  }, [seasons, selectedSeasonId]);

  useEffect(() => {
    const race = races[selectedRaceId];

    if (race) {
      searchParams.set("r", String(selectedRaceId));
      searchParams.set("s", String(selectedSeasonId));
      setSearchParams(searchParams);

      onRaceChange(selectedRaceId);

      const options = race.sprintResults ? sprintOptions : defaultOptions;

      setRaceTypeOptions(options);
      setSelectedRaceType(options[options.length - 1]);
    }
  }, [races, selectedRaceId]); // eslint-disable-line

  const round = useMemo(() => {
    if (!selectedRaceId || !seasons[selectedSeasonId]) return null;

    const seasonRaces = seasons[selectedSeasonId].races;
    return seasonRaces?.findIndex((item) => item.id === selectedRaceId) + 1;
  }, [seasons, selectedRaceId]); // eslint-disable-line

  useEffect(() => {
    onRaceTypeChange(selectedRaceType);
  }, [selectedRaceType]); // eslint-disable-line

  useEffect(() => {
    loadSchedule(seasonIds);
  }, []); // eslint-disable-line

  const selectedSeason = seasons[selectedSeasonId];

  if (!selectedSeason) {
    return <></>; //TODO: add skeleton
  }

  return (
    <Fragment>
      <Container>
        <SelectorContainer>
          <Selector
            selectedValue={selectedSeason}
            onChange={(value) => setSelectedSeasonId(value.id)}
            values={Object.values(seasons).filter(
              (season) => season.id !== 34 //HARDCODE
            )}
            getTitle={(value) => value.shortName}
          />
        </SelectorContainer>
        <SelectorContainer>
          {selectedRaceId && (
            <Selector
              selectedValue={selectedSeason.races.find(
                (item) => item.id === selectedRaceId
              )}
              onChange={(value) => setSelectedRaceId(value.id)}
              values={selectedSeason.races}
              isDisabled={(item) => !item.hasResults}
              getTitle={(value, i) => `${i + 1}   ${value.shortName}`}
            />
          )}
        </SelectorContainer>
        <SelectorContainer>
          <Selector
            selectedValue={selectedRaceType}
            onChange={setSelectedRaceType}
            values={raceTypeOptions}
            getTitle={(value) => value.title}
            isDisabled={(item) =>
              !hasResultsByType(races[selectedRaceId], item.key)
            }
          />
        </SelectorContainer>
      </Container>

      <RaceTitleContainer>
        <TitleSelectorContainer>
          {`${selectedSeason.shortName} Етап ${round}`}
        </TitleSelectorContainer>
        <DashElement />

        {seasonRace && (
          <TitleSelectorContainer>{`${seasonRace.shortName}  -  ${selectedRaceType.title}`}</TitleSelectorContainer>
        )}
      </RaceTitleContainer>
    </Fragment>
  );
};

export default RaceSelector;
