const gapi = window.gapi;

export let loaded = false;

export async function loadClient() {
  gapi.client.setApiKey("AIzaSyBGlRmNXV14Z_iBFM5EkmbSf4RFUi8w-fU");
  await gapi.client.load("youtube", "v3");
  loaded = true;
}

export default gapi;
