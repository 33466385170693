import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import theme from "../../styles/theme";
import Links from "./Links";

const irBlueColor = "#2E358D";

const HeaderTopContainer = styled.div`
  background-color: ${irBlueColor};
  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    display: none;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  max-width: ${theme.breakpointsCustom.max}px;
  margin: auto;
  padding: 1px 5px 1px 10px;
`;

const StyledLinkBottom = styled(NavLink)`
  text-decoration: none;
  color: ${theme.colors.white};
  font-size: 14px;
   font-weight: 500;
  font-family: "Montserrat", sans-serif;sans-serif;
  transition: all 0.2s;
  &.active {
    color: ${theme.colors.green};
    font-weight: 500;
  }
`;

const HeaderTopLinksSocial = styled.div``;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 15px;
  padding-right: 15px;
`;
const HeaderTopNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 15px;
`;

const TopHeader = () => {
  return (
    <HeaderTopContainer>
      <HeaderTop>
        <HeaderTopLinksSocial>
          <Links color={theme.colors.white} />
        </HeaderTopLinksSocial>
        <HeaderTopNavigation>
          <LinkContainer>
            <StyledLinkBottom to="/about-us">Про Нас</StyledLinkBottom>
            <StyledLinkBottom to="/faq">Регламент</StyledLinkBottom>
          </LinkContainer>
        </HeaderTopNavigation>
      </HeaderTop>
    </HeaderTopContainer>
  );
};

export default TopHeader;
