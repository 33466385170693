import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import styled from "styled-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import BlockTitle from "../Common/BlockTitle";
import theme from "../../styles/theme";
import { archiveItems } from "../../constants/archive";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const SliderContainer = styled.div`
  gap: 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const StyledSwiper = styled(Swiper)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  transition: all 0.3s linear;

  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    grid-template-columns: 1fr;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const StyledImg = styled.img`
  transition: transform 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
`;

const StyledSwiperContainer = styled(SwiperSlide)`
  position: relative;
  box-shadow: #00000040 0px 2px 8px 0px;
  height: fit-content;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;

  &:hover {
    ${StyledImg} {
      transform: scale(1.03);
    }
  }
`;

const StyledIconPrev = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;

const StyledIconNext = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;

export default function Slider() {
  return (
    <>
      <BlockTitle>
        Архів
        <div>
          <StyledIconPrev className="icon-arrow-long-left review-swiper-button-prev">
            <IconButton>
              <KeyboardArrowLeftIcon sx={{ fontSize: "38px" }} />
            </IconButton>
          </StyledIconPrev>

          <StyledIconNext className="icon-arrow-long-right review-swiper-button-next">
            <IconButton>
              <KeyboardArrowRightIcon sx={{ fontSize: "38px" }} />
            </IconButton>
          </StyledIconNext>
        </div>
      </BlockTitle>
      <SliderContainer>
        <StyledSwiper
          style={{ width: "100%" }}
          watchSlidesProgress={false}
          slidesPerView={"auto"}
          spaceBetween={16}
          navigation={{
            nextEl: ".review-swiper-button-next",
            prevEl: ".review-swiper-button-prev",
          }}
          modules={[Navigation, Pagination]}
        >
          {archiveItems.map((item) => (
            <StyledSwiperContainer>
              <SwiperSlide>
                <StyledImg img src={item.image} alt=""></StyledImg>
                <StyledLink to={item.link}></StyledLink>
              </SwiperSlide>
            </StyledSwiperContainer>
          ))}
        </StyledSwiper>
      </SliderContainer>
    </>
  );
}
