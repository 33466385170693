import React from "react";
import styled from "styled-components";
import monoBankImage from "../../assets/monobank.png";
import theme from "../../styles/theme";

const DonateButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  background: none;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid ${theme.colors.grey};
  transition: all 0.1s linear;
  text-decoration: none;

  &:hover {
    background-color: #3c4043;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    display: ${({ showLinks }) => (showLinks ? "flex" : "none")};
  }
`;

const StyledSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
`;

const MonoBankImage = styled.img`
  width: 20px;
`;

const DonateLink = ({ showLinks }) => {
  return (
    <DonateButton
      href="https://send.monobank.ua/jar/59VfGdTtvE"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="link"
      showLinks={showLinks}
    >
      <StyledSpan style={{ textDecoration: "none", color: "white" }}>
        <MonoBankImage src={monoBankImage}></MonoBankImage>Підтримати проєкт
      </StyledSpan>
    </DonateButton>
  );
};

export default DonateLink;
