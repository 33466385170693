import styled from "styled-components";

import theme from "../../styles/theme";

export const TransparentButton = styled.div`
  color: ${theme.colors.white}97;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 24px;
    color: ${theme.colors.white};
  }
`;

export default TransparentButton;
