import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, NavLink } from "react-router-dom";

import theme from "../../styles/theme";
import MenuItem from "./MenuItem";
import Links from "./Links";
import logo from "../../assets/logowhite.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import headerItems from "./headerItems";
import DonateLink from "./DonateLink";

const BlockTitlePhone = styled.div`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border-bottom: 1px solid ${theme.colors.grey};
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderMobileContainer = styled.div`
  background-color: ${theme.colors.darkLight};
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 10;
  font-family: "Montserrat", sans-serif;
  box-shadow: #00000040 0px 2px 8px 0px;
  @media screen and (min-width: ${theme.breakpointsCustom.mobile + 1}px) {
    display: none;
  }
`;

const HeaderMobileContent = styled.div`
  max-width: ${theme.breakpointsCustom.max}px;
  margin: 0 auto;
  @media (min-width: ${theme.breakpointsCustom.mobile}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
  }
`;

const MenuItemsContainer = styled.div`
  transition: all 0.3s cubic-bezier(0.65, 0.06, 0.24, 0.98);
  overflow: hidden;
  height: ${({ showLinks }) => (showLinks ? "calc(100vh - 60px)" : "0")};

  @media (min-width: ${theme.breakpointsCustom.mobile + 1}px) {
    height: auto;
    display: flex;
  }

  @media (max-width: ${theme.breakpointsCustom.mobile + 1}px) {
    padding: ${({ showLinks }) => (showLinks ? "12px 12px" : "0px 12px")};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 23px;
    overflow: auto;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpointsCustom.md}px) {
    padding: 0;
  }

  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    padding: 0.7rem 12px;
  }
`;

const LogoImage = styled.img`
  height: 35px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 1.4rem;
  color: ${({ showLinks }) => (showLinks ? `${theme.colors.green};` : "white")};
  padding-right: 0px;
  transition: transform 0.3s linear;
  transform: ${({ showLinks }) =>
    showLinks ? "rotate(90deg)" : "rotate(0deg)"};
  transform-origin: center;
  cursor: pointer;

  @media (min-width: ${theme.breakpointsCustom.mobile + 1}px) {
    display: none;
  }
`;

const StyledLinkBottom = styled(NavLink)`
  text-decoration: none;
  color: ${theme.colors.grey};
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s;
  &.active {
    color: ${theme.colors.green};
  }
`;

const Kek = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;
const Lol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 100px;
`;

const Iro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MobileHeader = () => {
  const location = useLocation();
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks((prevShowLinks) => !prevShowLinks);
  };

  const handleLinkClick = () => {
    setShowLinks(false);
  };

  useEffect(() => {
    if (showLinks) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showLinks]);

  return (
    <HeaderMobileContainer>
      <HeaderMobileContent>
        <IconContainer>
          <Link
            to="/"
            style={{
              color: "inherit",
              textDecoration: "none",
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleLinkClick}
          >
            <LogoImage src={logo} />
          </Link>
          <StyledFontAwesomeIcon
            icon={faBars}
            style={{
              fontSize: "25px",
            }}
            showLinks={showLinks}
            onClick={toggleLinks}
          />
        </IconContainer>

        <MenuItemsContainer showLinks={showLinks}>
          <Kek>
            {headerItems.map((item, index) => {
              item.highlighted = item.link === location.pathname;

              return (
                <MenuItem
                  key={index}
                  {...item}
                  onClick={handleLinkClick}
                  showLinks={showLinks}
                />
              );
            })}
          </Kek>

          <Lol>
            <Iro>
              <StyledLinkBottom to="/about-us" onClick={handleLinkClick}>
                Про Нас
              </StyledLinkBottom>
              <StyledLinkBottom to="/faq" onClick={handleLinkClick}>
                FAQ
              </StyledLinkBottom>
            </Iro>
            <BlockTitlePhone />
            <Links
              showLinks={showLinks}
              onClick={handleLinkClick}
              color={theme.colors.white}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <DonateLink showLinks={showLinks} />
            </div>
          </Lol>
        </MenuItemsContainer>
      </HeaderMobileContent>
    </HeaderMobileContainer>
  );
};

export default MobileHeader;
