import React, { Fragment, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import BlockTitle from "../components/Common/BlockTitle";
import TransparentButton from "../components/Common/Buttons";
import theme from "../styles/theme";
import Archive from "../components/Archive/Slider";
import { loadVideosByIds, loadStreams } from "../utils/googleApi/youtube";
import { useHighlightsContext } from "../contexts/highlights";

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s linear;

  @media (max-width: 580px) {
    grid-template-columns: 1fr 1fr 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const VideoPost = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: #00000040 0px 2px 8px 0px;
  color: ${theme.colors.white};
  flex: 1;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${theme.colors.darkLight};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondaryDark};
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const TitleContainer = styled.div`
  padding: 20px 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;

const Date = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  opacity: 0.5;
`;

const StyledLink = styled(Link)`
  cursor: default;
  text-decoration: none;
  color: inherit;
`;

const VideoPostComponent = ({ link, image, title, date }) => {
  return (
    <StyledLink to={link} target="_blank" rel="noopener noreferrer">
      <VideoPost>
        <VideoContainer>
          <VideoImage src={image} />
        </VideoContainer>
        <TitleContainer>
          <Date>{date}</Date>
          <Title>{title}</Title>
        </TitleContainer>
      </VideoPost>
    </StyledLink>
  );
};

const VideoPostSkeleton = () => {
  return (
    <VideoPost>
      <VideoContainer>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </VideoContainer>
      <TitleContainer>
        <Skeleton width="60%" />
        <Skeleton />
      </TitleContainer>
    </VideoPost>
  );
};

export const StreamsBlock = () => {
  const [streams, setStreams] = useState([]);

  const loadStreamsVideos = async () => {
    const videos = await loadStreams();
    setStreams(videos);
  };

  useEffect(() => {
    loadStreamsVideos();
  }, []);

  return (
    <>
      <BlockTitle>
        Трансляції
        <StyledLink to="/videos/streams">
          <TransparentButton>Переглянути все</TransparentButton>
        </StyledLink>
      </BlockTitle>
      <PostsContainer>
        {streams.length
          ? streams
              .slice(0, 3)
              .map((item, key) => <VideoPostComponent {...item} />)
          : [1, 2, 3].map((item) => <VideoPostSkeleton />)}
      </PostsContainer>
    </>
  );
};

export const HighlightsBlock = () => {
  const { seasonHightlights, loadSeasonHighlights } = useHighlightsContext();
  const seasonId = 7;

  const last3Hightlights = useMemo(() => {
    return seasonHightlights[seasonId]?.slice(-3).reverse() || [];
  }, [seasonId, seasonHightlights]);

  useEffect(() => {
    loadSeasonHighlights(seasonId);
    // eslint-disable-next-line
  }, [seasonId]);

  return (
    <>
      <BlockTitle>
        Огляди
        <StyledLink to="/videos/seasons/7">
          <TransparentButton>Переглянути все</TransparentButton>
        </StyledLink>
      </BlockTitle>
      <PostsContainer>
        {last3Hightlights.length
          ? last3Hightlights.map((item, key) => (
              <VideoPostComponent {...item} />
            ))
          : [1, 2, 3].map((item) => <VideoPostSkeleton />)}
      </PostsContainer>
    </>
  );
};

const Videos = () => {
  const [conversations, setConversations] = useState([]);

  const loadConversations = async () => {
    const videos = await loadVideosByIds([
      "Lxzh7mDZi04",
      "_OzwnG9KT24",
      "cI_TsUqzDRU",
    ]);
    setConversations(videos);
  };

  useEffect(() => {
    loadConversations();
  }, []);

  return (
    <Fragment>
      <div style={{ marginTop: 24 }}>
        <HighlightsBlock />
      </div>
      <div style={{ marginTop: 48 }}>
        <StreamsBlock />
      </div>
      <div style={{ marginTop: 48 }}>
        <BlockTitle>
          Інтерв'ю
          <StyledLink to="/videos/conversations">
            <TransparentButton>Переглянути все</TransparentButton>
          </StyledLink>
        </BlockTitle>
        <PostsContainer>
          {conversations.length
            ? conversations.map((item, key) => <VideoPostComponent {...item} />)
            : [1, 2, 3].map((item) => <VideoPostSkeleton />)}
        </PostsContainer>
      </div>
      <div style={{ marginTop: 48 }}>
        <Archive />
      </div>
    </Fragment>
  );
};

export default Videos;
