export const colors = {
  dark: "#1E1E1E",
  darkLight: "#2a2a2a",
  green: "#aeff00",
  grey: "#67676d",
  white: "#eeeeee",
  secondaryDark: "#393B40",
  red: "red",
};

export const breakpointsCustom = {
  mobile: 480,
  sm: 576,
  md: 768,
  lg: 992,
  max: 1320,
};
