import React, { useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useParams } from "react-router-dom";
import Link from "../../components/Common/Link";
import styled from "styled-components";

import theme from "../../styles/theme";
import CustomizedAccordions from "../../components/Drivers/AwardsAccordion";
import BonusAccordions from "../../components/Drivers/BonusAccordion";
import SeasonsTable from "../../components/Drivers/CareerTable";
import { useDriverContext } from "../../contexts/driver";
import NoneDriver from "../../assets/none_driver.png";
import { useNewsContext } from "../../contexts/news";
import { PostItem } from "../../components/News/NewsItem";
import SocialMediaLinks from "../../components/Driver/SocialMediaLinks";
import TransparentButton from "../../components/Common/Buttons";
import { isDark } from "../../utils/helpers/colors";

const BlockTitle = styled.div`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border-bottom: 8px solid ${(props) => props.borderColor || theme.colors.green};
  margin-bottom: 20px;
  padding-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: row;
  align-items: end;
`;

const ImageContainer = styled.div`
  max-width: 320px;
  width: 100%;
  text-align: center;
  height: 150px;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    max-width: none;
    width: fit-content;
    height: 100%;
    display: flex;
  }
`;

const StyledImage = styled.img`
  width: 150px;
  height: 150px;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    width: 120px;
    height: 120px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DriverName = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin-left: 16px;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    font-size: 26px;
    margin-left: 16px;
  }
`;

const ButtonsTitleContainer = styled.div`
  display: flex;
  align-items: end;
`;

const ReviewButton = styled.button`
  width: 92px;
  height: 32px;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${(props) => props.borderColor || theme.colors.green};
  outline: none;
  border: none;
  color: ${(props) => props.textColor || "black"};
  font-family: "Montserrat", sans-serif;
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

const LeftSideContainer = styled.div`
  background: #2a2a2ac4;
  max-width: 320px;
  height: fit-content;
  width: 100%;
  border: 2px solid ${theme.colors.secondaryDark};
  padding: 16px 16px 16px 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    width: 100%;
    max-width: none;
  }
`;

const RowContainer = styled.div`
  border-bottom: 2px solid ${theme.colors.secondaryDark};
  padding-bottom: 8px;
`;

const DriverTeam = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SlickHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-top: 4px;
`;

const SlickHistoryBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SmallerTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
`;

const Quantity = styled.div`
  color: #f58020;
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: ${(props) => props.color || theme.colors.green};
`;

const RightSideContainer = styled.div`
  margin-left: 16px;
  width: 100%;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    margin-left: 0px;
  }
`;

const NewsContainer = styled.div`
  margin-top: 24px;
  background: #2a2a2ac4;
  border: 2px solid #393b40;
  padding: 16px;
  border-radius: 4px;
  width: fit-content;
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    width: 100%;
  }
`;
const FilteredPostContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media (max-width: ${theme.breakpointsCustom.md}px) {
    justify-content: center;
  }
`;

const TeamIconImage = styled.img`
  width: 16px;
  height: 16px;
`;
const TeamContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

const PostContainer = styled.div`
  max-width: 300px;

  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    max-width: none;
  }
`;

const DriverProfile = () => {
  const { setSelectedDriver, driverDetails } = useDriverContext();
  const { id } = useParams();
  const { filteredPosts, loadPosts, tags } = useNewsContext();

  useEffect(() => {
    if (id) {
      setSelectedDriver({ id });
    }
  }, [id, setSelectedDriver]);

  const driverFullName = useMemo(() => {
    if (!driverDetails) return "null";
    return `${driverDetails.firstName} ${driverDetails.lastName}`;
  }, [driverDetails]);

  useEffect(() => {
    if (driverFullName) {
      loadPosts({
        filter: { tags: { name: driverFullName } },
      });
    }
  }, [driverFullName, loadPosts]);

  useEffect(
    () => () => {
      window.document.title = `Slick Racing Ukraine`;
    },
    []
  );

  if (!driverDetails) {
    return <div>Пілота не знайдено</div>;
  }

  const socialNetworks = driverDetails.socialNetworks || [];
  const achievements = Object.values(driverDetails.achievements || {}).flat();
  const carrier = driverDetails.carrier || [];
  const team = driverDetails.team || [];
  const awards = driverDetails.awards || [];
  const buttonColor = driverDetails.team?.color || "white";
  const textColor = isDark(buttonColor) ? "white" : "black";

  window.document.title = `${driverFullName} | Slick Racing Ukraine`;

  return (
    <div style={{ marginTop: 24 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ underline: "hover", color: "inherit" }} to="/">
          Головна
        </Link>
        <Link style={{ underline: "hover", color: "inherit" }} to="/drivers">
          Пілоти
        </Link>
        <Typography sx={{ color: "#c1c1c1", cursor: "default" }}>
          {driverFullName}
        </Typography>
      </Breadcrumbs>
      <div style={{ marginTop: 24 }}>
        <BlockTitle borderColor={driverDetails.team?.color}>
          <ImageContainer>
            <StyledImage src={driverDetails.image || NoneDriver} alt="img" />
          </ImageContainer>
          <TitleContainer>
            <DriverName>{driverFullName}</DriverName>
            <ButtonsTitleContainer>
              <ReviewButton
                borderColor={driverDetails.team?.color}
                textColor={textColor}
              >
                Огляд
              </ReviewButton>
            </ButtonsTitleContainer>
          </TitleContainer>
        </BlockTitle>

        <MainContainer>
          <LeftSideContainer>
            {team && Object.keys(team).length > 0 && (
              <RowContainer>
                <DriverTeam>
                  <Title>Команда</Title>
                  <TeamContainer>
                    <TeamIconImage src={team.logoImage} />
                    <SmallerTitle>{team.name}</SmallerTitle>
                  </TeamContainer>
                </DriverTeam>
              </RowContainer>
            )}
            {socialNetworks.length > 0 && (
              <RowContainer>
                <SocialMediaLinks socialNetworks={socialNetworks} />
              </RowContainer>
            )}

            <RowContainer>
              <Title>Slick Racing Історія</Title>
              <SlickHistoryContainer>
                <SlickHistoryBlock>
                  <SmallerTitle>Гонки</SmallerTitle>
                  <Quantity color={driverDetails.team?.color}>
                    {driverDetails.racingHistory[0]?.grandPrixCount || 0}
                  </Quantity>
                </SlickHistoryBlock>
                <SlickHistoryBlock>
                  <SmallerTitle>Очки</SmallerTitle>
                  <Quantity color={driverDetails.team?.color}>
                    {driverDetails.racingHistory[0]?.points || 0}
                  </Quantity>
                </SlickHistoryBlock>
              </SlickHistoryContainer>
            </RowContainer>
            {achievements.length > 0 && (
              <RowContainer style={{ borderBottom: "none" }}>
                <Title>Досягнення</Title>
                <CustomizedAccordions achievements={achievements} team={team} />
              </RowContainer>
            )}
            {awards.length > 0 && (
              <RowContainer style={{ borderBottom: "none" }}>
                <Title>Нагороди</Title>
                <BonusAccordions awards={awards} team={team} />
              </RowContainer>
            )}
          </LeftSideContainer>

          <RightSideContainer>
            <SeasonsTable seasons={carrier} team={team} />

            {filteredPosts.length > 0 && (
              <NewsContainer>
                <Title style={{ padding: "0px", marginBottom: "16px" }}>
                  Новини
                  {filteredPosts.length > 3 && (
                    <TransparentButton
                      style={{ fontSize: "12px" }}
                      onClick={() => {
                        const fullNameTag = `${driverDetails.firstName} ${driverDetails.lastName}`;
                        const tag = tags.find(
                          (tag) => tag.name === fullNameTag
                        );
                        if (tag) {
                          window.location.href = `/news?tags=${tag.id}`;
                        }
                      }}
                    >
                      Показати все
                    </TransparentButton>
                  )}
                </Title>

                <FilteredPostContainer>
                  {filteredPosts.slice(0, 3).map((post) => (
                    <PostContainer style={{}} key={post.id}>
                      <PostItem {...post} />
                    </PostContainer>
                  ))}
                </FilteredPostContainer>
              </NewsContainer>
            )}
          </RightSideContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default DriverProfile;
