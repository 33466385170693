import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import theme from "../../styles/theme";

const Title = styled.div`
font-family: "Montserrat", sans-serif;
`;

const MenuItemButton = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;

  & a {
    color: ${theme.colors.white};
    border-bottom: 2.5px solid transparent;
  }

  & a.active {
    // color: ${theme.colors.green};
    border-bottom: 2.5px transparent;
  }
  @media (min-width: ${theme.breakpointsCustom.mobile}px) {
    & a.active {
      // color: ${theme.colors.green};
      border-bottom: 2.5px solid ${theme.colors.green};
    }
    & > a {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 16px 16px 16px 16px;
      justify-content: space-between;
      width: 100%;
      transition: all 0.2s;
      // background: transparent;
      // border-bottom: 3px solid
      //   ${(props) =>
        props.highlighted ? theme.colors.green : "transparent"};
    }
    &:hover > a {
      background-color: rgb(48 48 49);
    }
  }
  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    &:hover > a {
      // border-bottom: 2.5px solid transparent;
    }
    border-bottom: 1px solid
      ${(props) =>
        props.highlighted ? theme.colors.green : theme.colors.white};
    border-right: 1px solid
      ${(props) =>
        props.highlighted ? theme.colors.green : theme.colors.white};
    border-bottom-right-radius: 10px;
    margin: 0px;
    background-color: initial;

    & > a {
      color: ${(props) =>
        props.highlighted ? theme.colors.green : theme.colors.white};
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      justify-content: space-between;
      width: 100%;
      border-bottom: 2.5px solid transparent;
    }
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  &.chevron-icon {
    @media (min-width: 480px) {
      display: none;
    }
  }
`;

const MenuItem = ({ title, color, link, highlighted = false, onClick }) => {
  return (
    <MenuItemButton highlighted={highlighted} onClick={onClick} color={color}>
      <NavLink to={link} style={{ textDecoration: "none" }}>
        <Title>{title}</Title>
        <StyledChevronRightIcon className="chevron-icon" />
      </NavLink>
    </MenuItemButton>
  );
};

export default MenuItem;
