import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import theme from "../../styles/theme";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `2px solid ${theme.colors.secondaryDark}`,
  "&::before": {
    display: "none",
  },
  backgroundColor: "transparent",
}));

const AccordionSummary = styled(({ teamColor, ...props }) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "12px", color: teamColor }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  padding: "0px 0px 0px 32px",
  minHeight: "0px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0px 16px 16px",
  borderTop: "none",
  backgroundColor: "transparent",
}));

export default function CustomizedAccordions({ achievements, team }) {
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded ? [...prevExpanded, panel] : prevExpanded.filter((p) => p !== panel)
    );
  };

  const achievementSeasons = {
    champions: [],
    secondPlaces: [],
    thirdPlaces: [],
    constructorsChampions: [],
    constructorsSecondPlaces: [],
    constructorsThirdPlaces: [],
  };

  achievements.forEach((a) => {
    if (a.name === "Чемпіон") {
      achievementSeasons.champions.push(a.at);
    }
    if (a.name === "Друге місце") {
      achievementSeasons.secondPlaces.push(a.at);
    }
    if (a.name === "Третє місце") {
      achievementSeasons.thirdPlaces.push(a.at);
    }
    if (a.name === "Чемпіон Кубку Конструкторів") {
      achievementSeasons.constructorsChampions.push(a.at);
    }
    if (a.name === "Друге місце Кубку Конструкторів") {
      achievementSeasons.constructorsSecondPlaces.push(a.at);
    }
    if (a.name === "Третє місце Кубку Конструкторів") {
      achievementSeasons.constructorsThirdPlaces.push(a.at);
    }
  });

  const groupedAchievements = {
    "Чемпіон особистого заліку": achievementSeasons.champions,
    "Друге місце особистого заліку": achievementSeasons.secondPlaces,
    "Третє місце особистого заліку": achievementSeasons.thirdPlaces,
    "Чемпіон кубку конструкторів": achievementSeasons.constructorsChampions,
    "Друге місце кубку конструкторів": achievementSeasons.constructorsSecondPlaces,
    "Третє місце кубку конструкторів": achievementSeasons.constructorsThirdPlaces,
  };

  return (
    <div>
      {Object.entries(groupedAchievements).map(([title, seasons], index) => {
        if (seasons.length === 0) return null;

        return (
          <Accordion
            key={index}
            expanded={expanded.includes(title)}
            onChange={handleChange(title)}
          >
            <AccordionSummary
              aria-controls={`${title}-content`}
              id={`${title}-header`}
              teamColor={team.color ? team.color : theme.colors.green}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {title}
                <Typography
                  component="span"
                  sx={{
                    color: team.color ? team.color : theme.colors.green,
                    marginLeft: "10px",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {seasons.length}
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {seasons.map((season) => (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "0 0 0 40px",
                      fontFamily: "Montserrat,sans-serif",
                    }}
                    key={season}
                  >
                    {season}
                  </div>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
