import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faInstagram,
  faYoutube,
  // faTwitch,
  faTiktok,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { IconButton } from "@mui/material";
import theme from "../../styles/theme";

const LinksContainer = styled.div`
  display: none;
  @media screen and (min-width: 480px) {
    display: flex;
  }
  @media screen and (max-width: ${theme.breakpointsCustom.mobile}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;
  }
`;

const StyledButton = styled(IconButton)`
  && {
    max-width: 36px;
    max-height: 36px;
    border-radius: 5px;
    padding: 5px 20px 5px 5px;

    &:hover {
      background-color: transparent;

      .icon-disc {
        color: #7289da !important;
      }
      .icon-inst {
        color: #dd2a7b !important;
      }
      .icon-youtube {
        color: #ff0000 !important;
      }
      .icon-twitch {
        color: #6441a5 !important;
      }
      .icon-tiktok {
        color: #ff0050 !important;
      }
      .icon-telegram {
        color: #24a1de !important;
      }
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${({ color }) => color || theme.colors.white};
  @media screen and (max-width: ${theme.breakpointsCustom.mobile}px) {
    font-size: 17px;
  }
`;

const Links = ({ showLinks, onClick, color }) => {
  return (
    <LinksContainer showLinks={showLinks}>
      <StyledButton
        variant="contained"
        target="_blank"
        href="https://discord.com/invite/slickracingua"
        onClick={onClick}
        sx={{
          maxWidth: "36px",
          padding: "5px 8px",
          maxHeight: "36px",
          borderRadius: "5px",
        }}
      >
        <StyledFontAwesomeIcon
          icon={faDiscord}
          className="icon-disc"
          color={color}
        />
      </StyledButton>
      <StyledButton
        variant="contained"
        href="https://www.instagram.com/slickracing.ukraine"
        target="_blank"
        onClick={onClick}
        sx={{
          maxWidth: "36px",
          maxHeight: "36px",
          borderRadius: "5px",
          padding: "5px 8px",
        }}
      >
        <StyledFontAwesomeIcon
          icon={faInstagram}
          className="icon-inst"
          color={color}
        />
      </StyledButton>
      <StyledButton
        variant="contained"
        href="https://www.youtube.com/@slickracingukraine"
        target="_blank"
        onClick={onClick}
        sx={{
          maxWidth: "36px",
          maxHeight: "36px",
          borderRadius: "5px",
          padding: "5px 8px",
        }}
      >
        <StyledFontAwesomeIcon
          icon={faYoutube}
          className="icon-youtube"
          color={color}
        />
      </StyledButton>
      <StyledButton
        variant="contained"
        target="_blank"
        onClick={onClick}
        href="https://www.tiktok.com/@slickracingua"
        sx={{
          maxWidth: "36px",
          maxHeight: "36px",
          borderRadius: "5px",
          padding: "5px 8px",
        }}
      >
        <StyledFontAwesomeIcon
          icon={faTiktok}
          className="icon-tiktok"
          color={color}
        />
      </StyledButton>
      <StyledButton
        variant="contained"
        target="_blank"
        onClick={onClick}
        href="https://t.me/slickracingukraine"
        sx={{
          maxWidth: "36px",
          maxHeight: "36px",
          borderRadius: "5px",
          padding: "5px 8px",
        }}
      >
        <StyledFontAwesomeIcon
          icon={faTelegram}
          className="icon-telegram"
          color={color}
        />
      </StyledButton>
    </LinksContainer>
  );
};

export default Links;
