import React, { Fragment } from "react";
import styled from "styled-components";

import theme from "../../styles/theme";

const GlobalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  // margin-left: -1000px;
  // margin-top: 350px;
  min-height: ${(props) => `${props.height}px`};
  background-color: #eeeeee;
  opacity: 0.5;
  z-index: 0;
  transition: all 0.3s linear;
  background: repeating-linear-gradient(
    -45deg,
    #67676d,
    ${theme.colors.darkLight} -3px,
    ${theme.colors.dark} 3px,
    ${theme.colors.dark} 10px
  );
  @media (max-width: 1280px) {
    min-height: 320px;
  }
  @media (max-width: 1016px) {
    min-height: 280px;
  }
  @media (max-width: 860px) {
    min-height: 260px;
  }
  @media (max-width: 780px) {
    min-height: 1000px;
    display: flex;
    flex-direction: column;
  }
`;

const OpacityBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    ${theme.colors.dark} 0%,
    ${theme.colors.dark} 25%,
    rgba(255, 0, 0, 0) 45%,
    rgba(255, 0, 0, 0) 90%,
    ${theme.colors.dark} 100%
  );
`;

const BasicBackground = ({ height = 350 }) => {
  return (
    <Fragment>
      <GlobalBackground height={height}>
        <OpacityBackground />
      </GlobalBackground>
    </Fragment>
  );
};

export default BasicBackground;
