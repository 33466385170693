import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import theme from "../../styles/theme";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SelectorContainer = styled.div`
  position: relative;
  height: 140px;
  overflow: hidden;
  scroll-behavior: auto;
  width: 100%;

  @media screen and (max-width: ${theme.breakpointsCustom.sm}px) {
    height: auto;
  }
`;

const Options = styled.div`
  padding-left: 32px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 140px;

  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
`;

const OptionButton = styled.div`
  transition: all 0.2s ease-in-out;
  padding: 8px 0px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  // text-align: center;
  margin: 2px;
  cursor: pointer;
  color: ${theme.colors.white};

  &:hover {
    color: ${theme.colors.green};
  }

  &.not-selected > span {
    border-bottom: 2px solid transparent;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }

  &.selected > span {
    color: ${theme.colors.white};
    border-bottom: 2px solid ${theme.colors.green};
    font-weight: 600;
  }
`;

const ScrollButtonTop = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

const ScrollButtonBottom = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Navigation = styled.div`
  width: 30px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
`;

const StyledSpan = styled.span`
  padding-bottom: 4px;
  white-space: pre-wrap;
`;
const Selector = ({
  values,
  selectedValue,
  getTitle,
  onChange,
  isDisabled,
}) => {
  const optionsEl = useRef(null);

  const scrollTo = (selectedIndex) => {
    const scrollEl = optionsEl.current;
    const scrollPosition = selectedIndex * (40 + 4) - 50;

    if (scrollEl) {
      scrollEl.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const selectedIndex = values.findIndex(
      (value) => value.id === selectedValue.id
    );

    scrollTo(selectedIndex);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedValue) return;

    const selectedIndex = values.findIndex(
      (value) => value.id === selectedValue.id
    );

    scrollTo(selectedIndex);
    // eslint-disable-next-line
  }, [selectedValue]);

  const scroll = (direction) => {
    const scrollEl = optionsEl.current;

    if (scrollEl) {
      const scrollAmount = direction * 110;
      scrollEl.scrollTo({
        top: scrollEl.scrollTop + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <SelectorContainer>
      <Options ref={optionsEl}>
        {values.map((value, i) => {
          const isSelected = selectedValue && selectedValue.id === value.id;
          const disabled = isDisabled?.(value);

          const spanClass = disabled
            ? "disabled"
            : isSelected
            ? "selected"
            : "not-selected";
          return (
            <OptionButton
              key={i}
              onClick={() => onChange(value)}
              className={spanClass}
            >
              <StyledSpan>{getTitle(value, i)}</StyledSpan>
            </OptionButton>
          );
        })}
      </Options>
      {values.length > 3 && (
        <Navigation>
          <ScrollButtonTop onClick={() => scroll(-1)}>
            <KeyboardArrowUpIcon sx={{ color: "white", fontSize: "36px" }} />
          </ScrollButtonTop>
          <ScrollButtonBottom onClick={() => scroll(1)}>
            <KeyboardArrowDownIcon sx={{ color: "white", fontSize: "36px" }} />
          </ScrollButtonBottom>
        </Navigation>
      )}
    </SelectorContainer>
  );
};

export default Selector;
