import React from "react";
import theme from "../styles/theme";
import styled from "styled-components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Container = styled.div`
  & blockquote {
    margin: 0 0 25px 12px;
    border-left: 2px solid ${theme.colors.grey};
    padding: 25px;
    background: ${theme.colors.grey}20;
    border-radius: 4px;
  }

  & h2 {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
  }
  & blockquote > p {
    font-size: 16px;
    margin: 0px;
    line-height: 2;
    font-family: "Montserrat", sans-serif;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  color: ${theme.colors.dark};
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;sans-serif;
`;

const Regulament = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const FrequentlyAskedQuestion = () => {
  return (
    <Container style={{ marginTop: "24px" }}>
      {/* <BlockTitle>Питання та відповіді</BlockTitle> */}
      {/* <h2>З якої платформи я можу зіграти?</h2>
      <blockquote>
        <p>PC/Xbox Series/PS4/PS5 </p>
      </blockquote>

      <h2>Як долучитись до гонок? / Як попасти в лігу?</h2>
      <blockquote>
        <p>
          У нас є 2 типи змагань:
          <br />
          <p>
            1) Ліга. Залікові гонки. <br />
            2) Заїзди без заліку. Фанові/тестові/перевірочні гонки. Ми називаємо
            їх Race Day. <br />
            Ці заїзди відкриті для усіх бажаючих, мають плаваючі
            правила/налаштування. <br />
            Для того, щоб попасти в лігу необхідно спочатку брати участь в Race
            Day (розклад можна подивитись в вкладці 'події/events'). <br />
            Залежно від результатів/чистоти пілотування, заявка на лігу може
            зайняти від 1-2 до 4-5 заїздів на Race Day. <br />
            Модератори будуть інформувати пілотів про результати та прийом
            заявки.
          </p>
        </p>
      </blockquote>

      <h2>Як приєднатися до лобі?</h2>
      <blockquote>
        <p>
          Для того щоб мати доступ до лобі, додайте наступні акаунти в друзі:
          <br />
          <a
            href="https://steamcommunity.com/id/vetal_kulyk"
            style={{ color: "white" }}
          >
            https://steamcommunity.com/id/vetal_kulyk
          </a>
          <br />
          <a
            href="https://steamcommunity.com/id/b4r0nnNNNNN"
            style={{ color: "white" }}
          >
            https://steamcommunity.com/id/b4r0nnNNNNN
          </a>
          <br />
          <a
            href="https://steamcommunity.com/id/oresthutsul"
            style={{ color: "white" }}
          >
            https://steamcommunity.com/id/oresthutsul
          </a>
          <br />
          <a
            href="https://steamcommunity.com/id/takeuchuha"
            style={{ color: "white" }}
          >
            https://steamcommunity.com/id/takeuchuha
          </a>{" "}
          <br />
          <br />
          EA Tags: <br />
          Vitalik_Potato
          <br />
          oresthutsul <br />
          b4r0nnNNNNN <br />
          dganarim
        </p>
      </blockquote> */}

      <h2>Формат чемпіонату</h2>
      <blockquote>
        <Regulament>
          <StyledLink
            to="https://docs.google.com/document/d/e/2PACX-1vTTji_Lv607EDT4W5PpwbzfCdaF-3W6-RO4BIEZAo2B9v9eIz82o5L8XM2kQhQKkJUi4RS1kZRA3eEm/pub"
            target="_blank"
          >
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{
                fontFamily: "Roboto Condensed",
                zIndex: 2,
              }}
              endIcon={<PictureAsPdfIcon sx={{}} />}
            >
              Регламент
            </Button>
          </StyledLink>
        </Regulament>
      </blockquote>
    </Container>
  );
};

export default FrequentlyAskedQuestion;
