import React from "react";
import styled from "styled-components";

import theme from "../../styles/theme";
import {
  PodiumConstructor,
  PodiumConstructorSkeleton,
} from "./PodiumConstructorCard";

const PodiumBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const PodiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SecondPlace = styled.div`
  flex: 1;
  margin-top: 70px;
  display: none;
  @media (min-width: ${theme.breakpointsCustom.md}px) {
    display: block;
  }
`;

const FirstPlace = styled.div`
  flex: 1;
  margin-right: 8px;
  margin-left: 8px;
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    display: flex;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const ThirdPlace = styled.div`
  flex: 1;
  margin-top: 70px;
  display: none;
  @media (min-width: ${theme.breakpointsCustom.md}px) {
    display: block;
  }
`;

const PodiumConstructors = ({ children, teams }) => {
  return (
    <PodiumBlock>
      {children}
      <PodiumContainer>
        <SecondPlace>
          {teams ? (
            <PodiumConstructor {...teams[1]} />
          ) : (
            <PodiumConstructorSkeleton />
          )}
        </SecondPlace>
        <FirstPlace>
          {teams ? (
            <PodiumConstructor {...teams[0]} />
          ) : (
            <PodiumConstructorSkeleton />
          )}
        </FirstPlace>
        <ThirdPlace>
          {teams ? (
            <PodiumConstructor {...teams[2]} />
          ) : (
            <PodiumConstructorSkeleton />
          )}
        </ThirdPlace>
      </PodiumContainer>
    </PodiumBlock>
  );
};

export default PodiumConstructors;
