import React from "react";

import ScheduleSlider from "../components/Schedule/ScheduleSlider";
import Standings from "../components/Season/Standings";
import VideoBlock from "../components/Video/VideoHomeBlock";
import NewsBlock from "../components/News/NewsLine";

export const HomeBlock = () => {
  return (
    <div style={{ marginTop: 48 }}>
      <ScheduleSlider />
      <Standings />
    </div>
  );
};

export const HomePageWrap = () => {
  return (
    <>
      <NewsBlock />
      <HomeBlock />
      <VideoBlock />
    </>
  );
};
