import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import theme from "../../styles/theme";
import MenuItem from "./MenuItem";
import logo from "../../assets/logowhite.svg";
import DropDownMenu from "./DropDown";
import headerItems from "./headerItems";
import DonateLink from "./DonateLink";

const HeaderContainer = styled.div`
  background-color: ${theme.colors.darkLight};
  position: sticky;
  top: 0px;
  z-index: 99;
  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    display: none;
  }
`;

const HeaderContent = styled.div`
  background-color: ${theme.colors.darkLight};
  max-width: ${theme.breakpointsCustom.max}px;
  margin: 0 auto;
  @media (min-width: ${theme.breakpointsCustom.mobile + 1}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
  }
`;

const MenuItemsContainer = styled.div`
  transition: all 0.3s cubic-bezier(0.65, 0.06, 0.24, 0.98);
  overflow: hidden;
  height: ${({ showLinks }) => (showLinks ? "calc(100vh - 50vh);" : "0")};

  @media (min-width: ${theme.breakpointsCustom.mobile + 1}px) {
    height: auto;
    display: flex;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpointsCustom.md + 1}px) {
    padding: 0;
  }

  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    padding: 0.7rem 12px;
  }
`;

const LogoImage = styled.img`
  height: 35px;
  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    display: none;
  }
`;

const Header = () => {
  const location = useLocation();
  const [showLinks, setShowLinks] = useState(false);

  const handleLinkClick = () => {
    setShowLinks(false);
  };

  useEffect(() => {
    if (showLinks) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showLinks]);

  return (
    <HeaderContainer>
      <HeaderContent>
        <IconContainer>
          <Link
            to="/"
            style={{
              color: "inherit",
              textDecoration: "none",
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleLinkClick}
          >
            <LogoImage src={logo} />
          </Link>
        </IconContainer>
        <MenuItemsContainer showLinks={showLinks}>
          {headerItems.map((item, index) => {
            item.highlighted = item.link === location.pathname;

            if (item.subItems) {
              return (
                <DropDownMenu
                  key={index}
                  {...item}
                  onClick={handleLinkClick}
                  showLinks={showLinks}
                />
              );
            } else {
              return (
                <MenuItem
                  key={index}
                  {...item}
                  onClick={handleLinkClick}
                  showLinks={showLinks}
                />
              );
            }
          })}
        </MenuItemsContainer>

        <DonateLink />
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
