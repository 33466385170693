import React from "react";
import styled from "styled-components";
import {
  ConstructorStandingsItem,
  ConstructorStandingsItemSkeleton,
} from "./ConstructorStandingsItem";
import _ from "lodash";

const StandingsContainer = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ConstructorStandings = ({ teams }) => {
  return (
    <StandingsContainer>
      {!teams
        ? _.times(10, () => <ConstructorStandingsItemSkeleton />)
        : teams.map((item, i) => (
            <ConstructorStandingsItem key={i} {...item} />
          ))}
    </StandingsContainer>
  );
};

export default ConstructorStandings;
