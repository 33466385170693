const menuItems = [
  {
    color: "#fca2fd",
    title: "Головна",
    link: "/",
  },
  {
    title: "Новини",
    link: "/news",
  },
  {
    title: "Відео",
    link: "/videos",
  },
  {
    title: "Результати",
    link: "/results",
  },
  {
    title: "Пілоти",
    link: "/drivers",
  },
];

export default menuItems;
