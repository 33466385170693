import React, { Fragment } from "react";
import styled from "styled-components";

import theme from "../../styles/theme";

const Background = styled.div`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  background: linear-gradient(
    90deg,
    ${theme.colors.darkLight} 0%,
    ${theme.colors.grey} 45%,
    ${theme.colors.grey} 55%,
    ${theme.colors.darkLight} 100%
  );
`;

const StyledImg = styled.img`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  object-fit: cover;
  opacity: 0.3;
`;

const PodiumBackground = ({ image }) => {


  return (
    <Fragment>
      <Background>
        <StyledImg src={image} />
      </Background>
    </Fragment>
  );
};

export default PodiumBackground;


