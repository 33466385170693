import _ from "lodash";

export const POST_IMAGE_SIZES = {
  small: "sm_webp",
  medium: "md_webp",
  large: "lg_webp",
};

export const getRelationItem = (item, name) => {
  const value = item[name].data;
  if (_.isArray(value)) {
    return value.map((i) => ({
      id: i.id,
      ...i.attributes,
    }));
  } else {
    return {
      id: item[name].data.id,
      ...item[name].data.attributes,
    };
  }
};

export const getPostImageUrl = (post, size) => {
  const image = getRelationItem(post, "mainImage");

  let url = image.url;
  if (size) {
    const newURL = image.formats?.[size]?.url;
    if (newURL) {
      url = newURL;
    }
  }
  return url;
};

export const getPostLink = (post) => {
  return `/news/${post.id}`;
};
