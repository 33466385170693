import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { IconButton } from "@mui/material";
import moment from "moment";

import styled from "styled-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import BlockTitle from "../Common/BlockTitle";
// import theme from "../../styles/theme";

import { ScheduleItemComponent, ScheduleItemSkeleton } from "./ScheduleItem";
import config from "../../config";
import { useScheduleContext } from "../../contexts/schedule";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const SliderContainer = styled.div`
  gap: 16px;
  max-width: 100%;
  margin: 0 auto;
`;

const StyledSwiper = styled(Swiper)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  transition: all 0.3s linear;
`;

const StyledIconPrev = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;

const StyledIconNext = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;

export default function Slider() {
  const lastSeason = config.constants.lastSeason;
  const { seasons, loadSchedule } = useScheduleContext();
  const [races, setRaces] = useState(null);
  const isMobile = window.innerWidth < 480;

  useEffect(() => {
    //if (seasons[lastSeason]) setRaces(seasons[lastSeason].races);
    if (seasons[lastSeason])
      setRaces(seasons[lastSeason].races.filter((r) => r.pointsRace));
  }, [seasons]); // eslint-disable-line

  useEffect(() => {
    loadSchedule(lastSeason);
    // eslint-disable-next-line
  }, []);

  const [swiper, setSwiper] = useState(null);
  const [closestRaceIndex, setClosestRaceIndex] = useState(null);

  const slideTo = useCallback(
    (index) => {
      swiper.slideTo(index, 1500);
    },
    [swiper]
  );

  useEffect(() => {
    if (races) {
      const closest = races.reduce((accumulator, currentValue, index) => {
        if (
          moment(currentValue.date, "YYYY-MM-DD") < moment().startOf("day") &&
          moment(currentValue.date, "YYYY-MM-DD") >
            moment(races[accumulator].date, "YYYY-MM-DD")
        ) {
          return index;
        } else return accumulator;
      }, 0);
      setClosestRaceIndex(closest + (isMobile ? 1 : 0));
    }
    // eslint-disable-next-line
  }, [races]);

  const testRacesCount = useMemo(() => {
    return races?.filter((i) => !i.pointsRace).length;
  }, [races]);

  useEffect(() => {
    if (races && swiper && closestRaceIndex) {
      setTimeout(() => {
        slideTo(closestRaceIndex);
      }, 500);
    }
  }, [swiper, races, closestRaceIndex, slideTo]);

  return (
    <>
      <BlockTitle>
        Календар
        <div>
          <StyledIconPrev className="icon-arrow-long-left review-swiper-button-prev-schedule-slider">
            <IconButton>
              <KeyboardArrowLeftIcon sx={{ fontSize: "38px" }} />
            </IconButton>
          </StyledIconPrev>

          <StyledIconNext className="icon-arrow-long-right review-swiper-button-next-schedule-slider">
            <IconButton>
              <KeyboardArrowRightIcon sx={{ fontSize: "38px" }} />
            </IconButton>
          </StyledIconNext>
        </div>
      </BlockTitle>
      <SliderContainer>
        <StyledSwiper
          onSwiper={setSwiper}
          style={{ width: "100%" }}
          speed={1000}
          initialSlide={0}
          watchSlidesProgress={false}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={16}
          navigation={{
            nextEl: ".review-swiper-button-next-schedule-slider",
            prevEl: ".review-swiper-button-prev-schedule-slider",
          }}
          modules={[Navigation, Pagination]}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 4,
            },
          }}
        >
          {races
            ? races.map((item, index) => {
                const round = item.pointsRace
                  ? `Етап ${index + 1 - testRacesCount}`
                  : `Test Race ${index + 1}`;
                const subtitle = `${seasons[lastSeason].shortName}, ${round}`;
                return (
                  <SwiperSlide key={index}>
                    <ScheduleItemComponent
                      subtitle={subtitle}
                      seasonId={seasons[lastSeason].id}
                      {...item}
                    />
                  </SwiperSlide>
                );
              })
            : [...new Array(4)].map((i) => (
                <SwiperSlide key={i}>
                  <ScheduleItemSkeleton />
                </SwiperSlide>
              ))}
        </StyledSwiper>
      </SliderContainer>
    </>
  );
}
