import React from "react";
import styled from "styled-components";

import theme from "../../styles/theme";
import { PodiumDriver, PodiumDriverSkeleton } from "./PodiumDriverCard";

const PodiumBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const PodiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SecondPlace = styled.div`
  flex: 1;
  margin-top: 70px;
  display: none;
  @media (min-width: ${theme.breakpointsCustom.md}px) {
    height: auto;
    display: flex;
  }
`;

const FirstPlace = styled.div`
  flex: 1;
  margin-right: 8px;
  margin-left: 8px;
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    display: flex;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const ThirdPlace = styled.div`
  flex: 1;
  margin-top: 70px;
  display: none;
  @media (min-width: ${theme.breakpointsCustom.md}px) {
    height: auto;
    display: flex;
  }
`;

const PoduimDrivers = ({ children, drivers }) => {
  return (
    <PodiumBlock>
      {children}
      <PodiumContainer>
        <SecondPlace>
          {!drivers ? (
            <PodiumDriverSkeleton />
          ) : (
            <PodiumDriver {...drivers[1]} />
          )}
        </SecondPlace>
        <FirstPlace>
          {!drivers ? (
            <PodiumDriverSkeleton />
          ) : (
            <PodiumDriver {...drivers[0]} />
          )}
        </FirstPlace>
        <ThirdPlace>
          {!drivers ? (
            <PodiumDriverSkeleton />
          ) : (
            <PodiumDriver {...drivers[2]} />
          )}
        </ThirdPlace>
      </PodiumContainer>
    </PodiumBlock>
  );
};

export default PoduimDrivers;
