import styled from "styled-components";

import theme from "../../styles/theme";

const BlockTitle = styled.div`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border-bottom: 3px solid ${theme.colors.darkLight};
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default BlockTitle;
