import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import theme from "../../styles/theme";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `2px solid ${theme.colors.secondaryDark}`,
  "&::before": {
    display: "none",
  },
  backgroundColor: "transparent",
}));

const AccordionSummary = styled(({ teamColor, ...props }) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "12px", color: teamColor }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  padding: "0px 0px 0px 32px",
  minHeight: "0px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px 16px",
  borderTop: "none",
  backgroundColor: "transparent",
}));

const BonusAccordions = ({ awards, team }) => {
  const [expanded, setExpanded] = React.useState("none");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Group awards by name
  const groupedAwards = awards.reduce((acc, award) => {
    const { name, description } = award;
    if (!acc[name]) {
      acc[name] = { count: 0, descriptions: [] };
    }
    acc[name].count += 1;
    acc[name].descriptions.push(description);
    return acc;
  }, {});

  return (
    <div>
      {Object.entries(groupedAwards).map(([title, { count, descriptions }], index) => (
        <Accordion
          key={index}
          expanded={expanded === title}
          onChange={handleChange(title)}
        >
          <AccordionSummary
          teamColor={team.color? team.color : theme.colors.green}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
             
              }}
            >
              {title}
              <Typography
                component="span"
                sx={{
                  color: `${team.color ? team.color : "#aeff00"}`,
                  marginLeft: "10px",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "12px",
                  fontWeight: "bold",
                     
                }}
              >
                {count}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {descriptions.map((desc, idx) => (
                <div
                  style={{
                    fontSize: "12px",
                    padding: "0 0 0 40px",
                    fontFamily: "Montserrat, sans-serif",
                    
                  }}
                  key={idx}
                >
                  {desc}
                </div>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default BonusAccordions;
