import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { axiosInstance } from "../utils/axios";

const DriverContext = createContext();

export const DriverProvider = ({ children }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverDetails, setDriverDetails] = useState(null);

  const fetchDriverDetails = useCallback(async (driverId) => {
    const response = await axiosInstance.get(`/drivers/${driverId}`);
    setDriverDetails(response.data);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedDriver) {
      fetchDriverDetails(selectedDriver.id);
    } else {
      setDriverDetails(null);
    }
  }, [selectedDriver, fetchDriverDetails]);

  return (
    <DriverContext.Provider
      value={{
        selectedDriver,
        setSelectedDriver,
        driverDetails,
      }}
    >
      {children}
    </DriverContext.Provider>
  );
};

export const useDriverContext = () => {
  return useContext(DriverContext);
};
