import React, { useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import styled from "styled-components";
import Link from "../../components/Common/Link";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import theme from "../../styles/theme";
import youtubeData from "../../constants/youtube";
import BlockTitle from "../../components/Common/BlockTitle";
import { useHighlightsContext } from "../../contexts/highlights";

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s linear;

  @media (max-width: 580px) {
    grid-template-columns: 1fr 1fr 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoPost = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: #00000040 0px 2px 8px 0px;
  color: ${theme.colors.white};
  flex: 1;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${theme.colors.darkLight};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondaryDark};
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const TitleContainer = styled.div`
  padding: 20px 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;

const Date = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  opacity: 0.5;
`;

const Block = styled.div`
  width: 100%;
  z-index: 3;
  margin: 24px 0;
  max-width: 1320px;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const VideoPostComponent = ({ link, image, title, date }) => {
  return (
    <StyledLink to={link} target="_blank" rel="noopener noreferrer">
      <VideoPost>
        <VideoContainer>
          <VideoImage src={image} />
        </VideoContainer>
        <TitleContainer>
          <Date>{date}</Date>
          <Title>{title}</Title>
        </TitleContainer>
      </VideoPost>
    </StyledLink>
  );
};

const VideoPostSkeleton = () => {
  return (
    <VideoPost>
      <VideoContainer>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </VideoContainer>
      <TitleContainer>
        <Skeleton width="60%" />
        <Skeleton />
      </TitleContainer>
    </VideoPost>
  );
};

const SeasonHighlights = () => {
  const { id } = useParams();
  const { seasonHightlights, loadSeasonHighlights } = useHighlightsContext();

  const hightlights = useMemo(() => {
    return seasonHightlights[id] || [];
  }, [id, seasonHightlights]);

  useEffect(() => {
    loadSeasonHighlights(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <div style={{ marginTop: 24 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ underline: "hover", color: "inherit" }} to="/">
          Головна
        </Link>
        <Link style={{ underline: "hover", color: "inherit" }} to="/videos">
          Відео
        </Link>
        <Typography sx={{ color: "#c1c1c1", cursor: "default" }}>
          {`Сезон ${id}`}
        </Typography>
      </Breadcrumbs>
      <MainContainer>
        <Block>
          <BlockTitle>
            {`Сезон ${id}`}
            <div>
              {!youtubeData.seasonHighlights[Number(id) + 1] ? (
                <IconButton disabled>
                  <KeyboardArrowLeftIcon sx={{ fontSize: "38px" }} />
                </IconButton>
              ) : (
                <Link to={`/videos/seasons/${Number(id) + 1}`}>
                  <IconButton>
                    <KeyboardArrowLeftIcon sx={{ fontSize: "38px" }} />
                  </IconButton>
                </Link>
              )}
              {!youtubeData.seasonHighlights[Number(id) - 1] ? (
                <IconButton disabled>
                  <KeyboardArrowRightIcon sx={{ fontSize: "38px" }} />
                </IconButton>
              ) : (
                <Link to={`/videos/seasons/${Number(id) - 1}`}>
                  <IconButton>
                    <KeyboardArrowRightIcon sx={{ fontSize: "38px" }} />
                  </IconButton>
                </Link>
              )}
            </div>
          </BlockTitle>
          <PostsContainer>
            {hightlights.length
              ? hightlights.map((item, key) => <VideoPostComponent {...item} />)
              : [1, 2, 3, 4, 5, 6].map((item) => <VideoPostSkeleton />)}
          </PostsContainer>
        </Block>
      </MainContainer>
    </div>
  );
};

export default SeasonHighlights;
