import React from "react";
import styled from "styled-components";

import theme from "../../styles/theme";
import Skeleton from "@mui/material/Skeleton";
import noneDriver from "../../assets/none_driver.png";
import { isDark } from "../../utils/helpers/colors";
import { useNavigate } from "react-router-dom";

const DriverBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-top: 15px;
  max-width: 100%;
  z-index: 2;
`;
const DriverName = styled.div`
  font-size: 24px;
  font-weight: 600;
  transition: color 0.3s ease;
`;

const DriverTeam = styled.div`
  transition: color 0.3s ease;
  color: ${theme.colors.grey};
`;
const DriverNumber = styled.div`
  color: ${theme.colors.grey}70;
  font-size: 36px;
  font-weight: 600;
  transition: color 0.3s ease;
`;

const StyledImg = styled.img`
  object-fit: cover;
  // transition: transform 0.5s 0.2s;
  z-index: 1;
`;
const DriverTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
`;

const Border = styled.div`
  border-top: 4px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  max-width: 350px;
  height: 0px;
  position: absolute;
  width: 100%;
  top: 66%;
  z-index: 2;
`;

const DriverContainer = styled.div`
  background-color: ${theme.colors.darkLight};
  box-shadow: 0px 0px 4px ${(props) => props.colorShadow};
  max-width: 360px;
  max-height: 285px;
  width: 100%;
  z-index: 2;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  &:hover ${DriverName}, &:hover ${DriverTeam} {
    color: ${(props) =>
      isDark(props.color) ? theme.colors.white : theme.colors.darkLight};
  }
  &:hover ${DriverNumber} {
    color: ${(props) =>
      isDark(props.color) ? theme.colors.white : theme.colors.darkLight};
  }
  &:hover ${StyledImg} {
    transition: transform 0.5s;
    transform: scale(1.08);
  }
  &:hover ${Border} {
    max-width: 100%;
    height: 35%;
    transition: max-width 0.1s ease, height 0.1s ease 0.1s;
  }
  &:not(:hover) ${Border} {
    height: 0px;
    max-width: 90%;
    transition: height 0.2s ease 0s, max-width 0.2s ease 0.2s;
  }
  &:not(:hover) ${StyledImg} {
    transition: transform 0.5s 0s;
  }
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    max-width: 500px;
  }
`;

// const Position = styled.div`
//   color: ${theme.colors.white};
//   height: 90px;
//   width: 33px;
//   background: ${(props) => props.color};
//   position: absolute;
//   top: -12%;
//   left: -3%;
//   transform: rotate(45deg);
// `;

const PointsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0%;
  right: 5%;
`;

const Points = styled.div`
  color: ${theme.colors.white};
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  width: 85px;
  background: ${theme.colors.dark};
`;
const PointsText = styled.div`
  color: ${theme.colors.dark};
  text-align: center;
  width: 85px;
  font-size: 24px;
  font-weight: 600;
  background: ${theme.colors.white};
`;

const DriverInfo = styled.div``;

export const PodiumDriverSkeleton = () => {
  return (
    <DriverContainer style={{ minHeight: "286.5px" }}>
      <Skeleton
        height="90px"
        width="33px"
        style={{
          borderRadius: "0px",
          position: "absolute",
          top: "-16%",
          left: "-1%",
          transform: "rotate(45deg)",
        }}
      />
      <PointsContainer style={{ top: "-9%" }}>
        <Skeleton height="95px" width="80px" style={{ borderRadius: "0px" }} />
      </PointsContainer>
      <DriverTopContainer style={{ height: "150px" }}>
        <Skeleton
          style={{
            borderRadius: "15px",
            width: "130px",
            height: "150px",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        />
      </DriverTopContainer>
      <Skeleton height="12px" width="100%" style={{ borderRadius: "0px" }} />
      <DriverBottomContainer style={{ paddingTop: "0px", marginLeft: "10px" }}>
        <DriverInfo>
          <DriverName>
            <Skeleton
              height="41px"
              width="150px"
              style={{ borderRadius: "12px" }}
            />
          </DriverName>
          <DriverTeam>
            <Skeleton
              height="20px"
              width="115px"
              style={{ borderRadius: "12px" }}
            />
          </DriverTeam>
        </DriverInfo>
        <DriverNumber>
          <Skeleton
            height="80px"
            width="36px"
            style={{ borderRadius: "12px" }}
          />
        </DriverNumber>
      </DriverBottomContainer>
    </DriverContainer>
  );
};

export const PodiumDriver = ({ driver, points, position }) => {
  let positionColor = "#cd7f32";
  let defaultColor = theme.colors.white;
  if (position === 1) positionColor = "#FFDF00";
  if (position === 2) positionColor = "#c0c0c0";

  const navigate = useNavigate(); 
  
  const handleClick = () => {
    navigate(`/drivers/${driver.id}`);
    };

  return (
    <DriverContainer
      color={driver.team?.color || defaultColor}
      colorShadow={positionColor}
      onClick={handleClick}
    >
      {/* <Position color={positionColor} /> */}
      <PointsContainer>
        <Points>{points}</Points>
        <PointsText>{`ОЧ.`}</PointsText>
      </PointsContainer>
      <DriverTopContainer>
        <StyledImg
          src={driver.image || noneDriver}
          style={{ width: "150px", height: "150px", marginTop: "20px" }}
        />
      </DriverTopContainer>
      <Border color={driver.team?.color || defaultColor} />
      <DriverBottomContainer>
        <DriverInfo>
          <DriverName>{`${driver.firstName} ${driver.lastName}`}</DriverName>
          <DriverTeam>{driver.team?.name || `reserve driver`}</DriverTeam>
        </DriverInfo>
        <DriverNumber>{!driver.number ? "" : driver.number}</DriverNumber>
      </DriverBottomContainer>
    </DriverContainer>
  );
};
