import React, { createContext, useCallback, useContext, useState } from "react";
import _ from "lodash";

import { axiosInstance } from "../utils/axios";

// Create a context with default values
const ScheduleContext = createContext({
  seasons: {},
});

// Custom hook to use the SeasonScheduleContext
export const useScheduleContext = () => {
  return useContext(ScheduleContext);
};

// SeasonScheduleProvider component that wraps your app
export const ScheduleProvider = ({ children }) => {
  const [seasons, setSeasons] = useState({});

  const fetchSeasonSchedule = useCallback(async (season) => {
    const { data } = await axiosInstance.get(`/seasons/${season}`);
    return data;
  }, []);

  const loadSeasonSchedule = useCallback(
    async (seasonId) => {
      if (seasons[seasonId]) return null; //already exists

      const data = await fetchSeasonSchedule(seasonId);
      return data;
    },
    [seasons, fetchSeasonSchedule]
  );

  const loadSchedule = useCallback(
    async (seasonIds) => {
      if (!_.isArray(seasonIds)) {
        seasonIds = [seasonIds];
      }

      const results = await Promise.all(seasonIds.map(loadSeasonSchedule));

      const resultObj = {};
      _.forEach(results, (season) => {
        if (season) resultObj[season.id] = season;
      });

      setSeasons((prevValue) => {
        return { ...prevValue, ...resultObj };
      });
    },
    [loadSeasonSchedule]
  );

  return (
    <ScheduleContext.Provider value={{ seasons, loadSchedule }}>
      {children}
    </ScheduleContext.Provider>
  );
};
