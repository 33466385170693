import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";

import theme from "../../styles/theme";
import { PostItem } from "../../components/News/NewsItem";
import BlockTitle from "../../components/Common/BlockTitle";
import { useNewsContext } from "../../contexts/news";
import TransparentButton from "../Common/Buttons";
import Link from "../Common/Link";

import { MainPostSkeleton, SecondaryPostSkeleton } from "./NewsPostHome";

const TopContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "a a b d"
    "a a c e";
  gap: 16px;
  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "a b"
      "c d";
  }
  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e";
  }
`;

const AreaA = styled.div`
  grid-area: a;
`;

const AreaB = styled.div`
  grid-area: b;
`;

const AreaC = styled.div`
  grid-area: c;
`;

const AreaD = styled.div`
  grid-area: d;
  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    display: none;
  }
`;

const AreaE = styled.div`
  grid-area: e;
  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    display: none;
  }
`;

const News = () => {
  const { posts, filteredPosts, filter, loadPosts } = useNewsContext();
  const [selectedTagIds] = useState([]);

  useEffect(() => {
    const newFilter = {};
    if (selectedTagIds.length) {
      _.assign(newFilter, {
        $and: selectedTagIds.map((tag) => ({ tags: { id: tag } })),
      });
    }

    loadPosts({ filter: newFilter });
  }, [selectedTagIds]); // eslint-disable-line

  const list = _.isEmpty(filter) ? posts : filteredPosts;

  return (
    <div style={{ marginTop: 24 }}>
      <BlockTitle>
        Новини
        <Link to="/news">
          <TransparentButton>Усі публікації</TransparentButton>
        </Link>
      </BlockTitle>
      <TopContainer>
        <AreaA>
          {list[0] ? (
            <PostItem key={list[0].id} {...list[0]} />
          ) : (
            <MainPostSkeleton />
          )}
        </AreaA>
        <AreaB>
          {list[1] ? (
            <PostItem key={list[1].id} {...list[1]} />
          ) : (
            <SecondaryPostSkeleton />
          )}
        </AreaB>
        <AreaC>
          {list[2] ? (
            <PostItem key={list[2].id} {...list[2]} />
          ) : (
            <SecondaryPostSkeleton />
          )}
        </AreaC>
        <AreaD>
          {list[3] ? (
            <PostItem key={list[3].id} {...list[3]} />
          ) : (
            <SecondaryPostSkeleton />
          )}
        </AreaD>
        <AreaE>
          {list[4] ? (
            <PostItem key={list[4].id} {...list[4]} />
          ) : (
            <SecondaryPostSkeleton />
          )}
        </AreaE>
      </TopContainer>
    </div>
  );
};

export default News;
