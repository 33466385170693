import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import { axiosInstance } from "../utils/axios";

const DriversContext = createContext({
  drivers: [],
});

export const useDriversContext = () => {
  return useContext(DriversContext);
};

export const DriversProvider = ({ children }) => {
  const [drivers, setDrivers] = useState([]);

  const defaultIRacingDriver = {
    id: 1,
    firstName: "Юрій",
    lastName: "Кравцов",
    number: 75,
    globalPoints: 0,
    discordId: "692682479168913418",
    image: "https://cdn.slickracing.com.ua/drivers/Yurii_Kravtsov.png",
    grandPrixCount: 0,
    driversAchievements: [],
    constructorAchievements: [],
    _links: {
      self: {
        href: "https://heroku.slickracing.com.ua/api/v1/drivers/1",
      },
    },
  };

  const fetchDrivers = async (simulator = "") => {
    const url = `/drivers?simulator=${simulator}`;
    const { data } = await axiosInstance.get(url, {
      params: { size: 200 },
    });

    const drivers = data?._embedded?.drivers || [];

    if (simulator === "iRacing" && drivers.length === 0) {
      return { drivers: [defaultIRacingDriver] };
    }

    return { drivers };
  };

  const loadDrivers = useCallback(async (simulator = "") => {
    const { drivers: loadedDrivers } = await fetchDrivers(simulator);
    setDrivers(loadedDrivers);
  }, []); // eslint-disable-line

  return (
    <DriversContext.Provider value={{ drivers, loadDrivers }}>
      {children}
    </DriversContext.Provider>
  );
};

