import axios from "axios";

import config from "../config";

export const axiosInstance = axios.create({
  baseURL: config.apiURL,
});

export const axiosCMSInstance = axios.create({
  baseURL: `${config.csmURL}/api`,
  headers: {
    Authorization: `Bearer ${config.cmsApiToken}`,
  },
});
