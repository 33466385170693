import React, { createContext, useCallback, useContext, useState } from "react";

import { axiosInstance } from "../utils/axios";

const RacesContext = createContext({
  races: {},
});

export const useRacesContext = () => {
  return useContext(RacesContext);
};

export const RacesProvider = ({ children }) => {
  const [races, setRaces] = useState({});

  const fetchRace = useCallback(async (id) => {
    const { data } = await axiosInstance.get(`/races/${id}`);
    return data;
  }, []);

  const loadRace = useCallback(
    async (id) => {
      if (races[id]) return; //already exists

      const race = await fetchRace(id);
      setRaces((prevValue) => {
        prevValue[id] = race;
        return { ...prevValue };
      });
    },
    // eslint-disable-next-line
    [fetchRace]
  );

  return (
    <RacesContext.Provider value={{ races, loadRace }}>
      {children}
    </RacesContext.Provider>
  );
};
