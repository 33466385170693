import React, { createContext, useCallback, useContext, useState } from "react";
import { axiosInstance } from "../utils/axios";

const StandingsContext = createContext({});

export const useStandingsContext = () => {
  return useContext(StandingsContext);
};

export const StandingsProvider = ({ children }) => {
  const [standings, setStandings] = useState({});

  const fetchStandingsDrivers = useCallback(async (season) => {
    const { data } = await axiosInstance.get(`/seasons/${season}/standings`);
    return data._embedded.standings;
  }, []);

  const fetchStandingsTeams = useCallback(async (season) => {
    const { data } = await axiosInstance.get(
      `/seasons/${season}/standings?group=teams`
    );
    return data._embedded.standings;
  }, []);

  const loadStandings = async (season) => {
    const drivers = await fetchStandingsDrivers(season);
    const teams = await fetchStandingsTeams(season);
    return { drivers, teams };
  };

  const loadSeason = async (season) => {
    if (season < 1 || standings[season]) return;
    const seasonStandings = await loadStandings(season);
    setStandings((prevData) => ({
      ...prevData,
      [season]: seasonStandings,
    }));
  };

  return (
    <StandingsContext.Provider
      value={{
        standings,
        loadSeason,
      }}
    >
      {children}
    </StandingsContext.Provider>
  );
};
