import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import theme from "../../styles/theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DropDown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropDownLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid ${theme.colors.grey};
  border-right: 2px solid ${theme.colors.grey};
  border-bottom-right-radius: 10px;
`;

const DropDownContentContainer = styled.div`
  max-width: 1120px;
  height: 90px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DropDownContent = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: rgb(48 48 49);
  left: 0;
  right: 0;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity 0.3s linear, max-height 0.3s linear;

  ${DropDown}:hover & {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ${(props) => (props.isTransitionDisabled ? "none" : "")};
    max-height: ${(props) => (props.isTransitionDisabled ? "0px" : "500px")};
  }
`;

const Dropbtn = styled.div`
  color: ${(props) =>
    props.highlighted ? theme.colors.green : theme.colors.white};
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  & > a {
    color: ${(props) =>
      props.highlighted ? theme.colors.green : theme.colors.white};
  }

  ${DropDown}:hover & {
    background-color: rgb(48 48 49);
  }
  &:hover {
    background-color: rgb(48 48 49);
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${theme.colors.white};
  font-size: 16px;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${theme.colors.grey};
  }
`;

const DropDownMenu = ({
  title,
  link,
  color,
  highlighted = false,
  subItems,
  onClick,
}) => {
  const [isTransitionDisabled, setTransitionDisabled] = useState(false);
  const handleLinkClick = () => {
    setTransitionDisabled(true);
    setTimeout(() => {
      setTransitionDisabled(false);
    }, 1000);
  };

  return (
    <DropDown>
      <Dropbtn
        highlighted={highlighted}
        onClick={onClick}
        onMouseDown={handleLinkClick}
      >
        <Link
          to={link}
          style={{
            color,
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            padding: "16px 13px 16px 16px",
            fontSize: "16px",
          }}
        >
          {title}
          <KeyboardArrowDownIcon
            sx={{ marginLeft: "1px", width: "22px", marginTop: "1px" }}
          />
        </Link>
      </Dropbtn>
      <DropDownContent isTransitionDisabled={isTransitionDisabled}>
        <DropDownContentContainer>
          <DropDownLinks>
            {subItems.map((subItem, subIndex) => (
              <StyledNavLink
                key={subIndex}
                to={subItem.link}
                style={{}}
                onClick={handleLinkClick}
              >
                {subItem.title}
              </StyledNavLink>
            ))}
          </DropDownLinks>
        </DropDownContentContainer>
      </DropDownContent>
    </DropDown>
  );
};

export default DropDownMenu;
