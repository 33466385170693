import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import "./App.css";

import BasicBackground from "./components/Backgrounds/BasicBackgound";
import theme from "./styles/theme";
import Header from "./components/Header/Header";
import WhiteHeader from "./components/Header/HeaderTop";
import MobileHeader from "./components/Header/MobileHeader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Footer from "./components/Footer/Footer";
import { HomePageWrap } from "./pages/Home";
import VideoPage from "./pages/Videos";
import DriversPage from "./pages/Drivers/Drivers";
import ResultsPage from "./pages/Results";
import AboutUsPage from "./pages/AboutUs/AboutUs";
import NewsPage from "./pages/News/News";
import NewsPostPage from "./pages/News/Post";
import FaqPage from "./pages/Faq";
import SeasonHighlights from "./pages/Videos/SeasonHighlights";
import ConversationsPage from "./pages/Videos/Conversations";
import StreamsPage from "./pages/Videos/Streams";
import DriverProfile from "./pages/Drivers/PersonalDriverProfile";

import CssBaseline from "@mui/material/CssBaseline";

import { HighlightsProvider } from "./contexts/highlights";
import { RacesProvider } from "./contexts/races";
import { StandingsProvider } from "./contexts/standings";
import { NewsProvider } from "./contexts/news";
import { DriversProvider } from "./contexts/drivers";
import { ScheduleProvider } from "./contexts/schedule";
import { DriverProvider } from "./contexts/driver";

import moment from "moment";
import "moment/locale/uk";

moment.locale("uk");

const GlobalContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 38px);
  max-width: 1320px;

  @media (max-width: ${theme.breakpointsCustom.mobile}px) {
    padding-top: 60px;
  }
`;

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NewsProvider>
        <DriversProvider>
          <DriverProvider>
            <ScheduleProvider>
              <HighlightsProvider>
                <StandingsProvider>
                  <RacesProvider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <WhiteHeader />
                      <Header />
                      <MobileHeader />
                      <BasicBackground height={800} />
                      <GlobalContainer>
                        <Routes>
                          <Route path="/" element={<HomePageWrap />} />
                          <Route path="/news" element={<NewsPage />} />
                          <Route path="/news/:id" element={<NewsPostPage />} />
                          <Route path="/videos" element={<VideoPage />} />
                          <Route path="/results" element={<ResultsPage />} />
                          <Route path="/drivers" element={<DriversPage />} />
                          <Route path="/about-us" element={<AboutUsPage />} />
                          <Route path="/faq" element={<FaqPage />} />

                          <Route
                            path="/videos/conversations"
                            element={<ConversationsPage />}
                          />

                          <Route
                            path="/videos/streams"
                            element={<StreamsPage />}
                          />

                          <Route
                            path="/videos/seasons/:id"
                            element={<SeasonHighlights />}
                          />

                          <Route
                            path="/drivers/:id"
                            element={<DriverProfile />}
                          />
                        </Routes>
                      </GlobalContainer>
                      <Footer />
                    </ThemeProvider>
                  </RacesProvider>
                </StandingsProvider>
              </HighlightsProvider>
            </ScheduleProvider>
          </DriverProvider>
        </DriversProvider>
      </NewsProvider>
    </BrowserRouter>
  );
}

export default App;
