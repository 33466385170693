import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Links from "../Header/Links";
import logo from "../../assets/logowhite.svg";
import KmamkLogo from "../../assets/kmamk_SR.png";
import theme from "../../styles/theme";

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.darkLight};
  display: flex;
  justify-content: center;
  box-shadow: #00000040 0px -2px 8px 0px;
  margin-top: 72px;
`;

const FooterContent = styled.div`
  max-width: 1320px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px 0px 12px;
  font-family: "Montserrat", sans-serif;sans-serif;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    padding: 24px 12px 0px 12px;
  }
`;

const Copyright = styled.div`
  color: white;
  font-weight: 400;
  color: ${theme.colors.grey};
  font-size: 12px;
  font-weight: 400;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    padding-left: 0px;
  }
`;

const StledImg = styled.img`
  height: 40px;
`;

const FooterTopContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 24px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;
const FotterNavLinkContainer = styled.div`
  flex-direction: row;
  gap: 50px;
  width: 100%;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const TopNavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    flex-direction: column;
    align-items: start;
    width: auto;
  }
`;

const BottomNavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    flex-direction: column;
    align-items: start;
    margin-top: 0px;
    width: auto;
  }
`;

const BottomSocLinksContainer = styled.div``;
const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 36px 0;
  gap: 12px;
`;

const Divider = styled.div`
  background: linear-gradient(
    90deg,
    rgba(46, 53, 141, 0) 15%,
    rgba(46, 53, 141, 1) 25%,
    rgba(46, 53, 141, 1) 75%,
    rgba(46, 53, 141, 0) 85%
  );
  height: 2px;
  width: 100vw;
  margin-top: 36px;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    display: none;
  }
`;

const StyledLinkBottom = styled(NavLink)`
  text-decoration: none;
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  &.active {
    color: ${theme.colors.green};
  }
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    font-size: 18px;
    color: ${theme.colors.white};
  }
`;

const LinksTopFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 12px;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    align-items: start;
    flex-direction: row;
    gap: 12px;
    width: 100%;
  }
`;
const FollowUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 400;
  gap: 12px;
  font-family: "Montserrat", sans-serif;sans-serif;
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    align-items: center;
    width: 100%;
    font-size: 18px;
  }
`;
const StledImgContainer = styled.div`
  @media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    border-bottom: 2px solid #d9d9d9;
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
  }
`;

const PartnersContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  media screen and (max-width: ${theme.breakpointsCustom.md}px) {
    align-items: center;
  }
`;

const PartnersTitle = styled.p`
  color: ${theme.colors.grey};
  align-self: end;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
`;

const Partners = styled.div`
  display: flex;
  gap: 24px;
`;

const PartnersLink = styled.a`
  padding: 0;
  margin: 0;
`;

const Footer = () => {
  return (
    <div>
      <FooterContainer>
        <FooterContent>
          <FooterTopContainer>
            <StledImgContainer>
              <StledImg src={logo} />
            </StledImgContainer>
            <FotterNavLinkContainer>
              <LinksContainer>
                <LinksTopFooterContainer>
                  <TopNavLinksContainer>
                    <StyledLinkBottom to="/">Головна</StyledLinkBottom>
                    <StyledLinkBottom to="/news">Новини</StyledLinkBottom>
                    <StyledLinkBottom to="/videos">Відео</StyledLinkBottom>
                    <StyledLinkBottom to="/results">
                      Результати
                    </StyledLinkBottom>
                    <StyledLinkBottom to="/drivers">Пілоти</StyledLinkBottom>
                  </TopNavLinksContainer>
                  <BottomNavLinksContainer>
                    <StyledLinkBottom to="/about-us">Про Нас</StyledLinkBottom>
                    <StyledLinkBottom to="/faq">Регламент</StyledLinkBottom>
                    {/* <StyledLinkBottom to="/">Merch</StyledLinkBottom> */}
                  </BottomNavLinksContainer>
                </LinksTopFooterContainer>
                <FollowUsContainer>
                  <BottomSocLinksContainer>
                    <PartnersContainer>
                      <PartnersTitle>Партнери:</PartnersTitle>
                      <Partners>
                        <PartnersLink
                          href="https://discord.com/invite/dhdn9xqhj6"
                          target="blank"
                        >
                          <StledImg src={KmamkLogo} />
                        </PartnersLink>
                        {/* <PartnersLink
                          href="https://discord.com/invite/dhdn9xqhj6"
                          target="blank"
                        >
                          <StledImg src={KmamkLogo} />
                        </PartnersLink> */}
                      </Partners>
                    </PartnersContainer>
                  </BottomSocLinksContainer>
                </FollowUsContainer>
              </LinksContainer>
            </FotterNavLinkContainer>
          </FooterTopContainer>
          <Divider></Divider>
          <FooterBottomContainer>
            <Links></Links>
            <Copyright>
              © Copyright 2024. Slick Racing Ukraine. All rights reserved.
            </Copyright>
          </FooterBottomContainer>
        </FooterContent>
      </FooterContainer>
    </div>
  );
};

export default Footer;
