import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faTiktok,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";

import VitaliiKulykImage from "../../assets/SlickTeam/VitaliiKulyk.jpg";
import OrestHutsulImage from "../../assets/SlickTeam/OrestHutsul.png";
import DmytroHorbanImage from "../../assets/SlickTeam/DmytroHorban.jpg";
import IgorDubokImage from "../../assets/SlickTeam/IgorDubok.png";
import YuriiKravtsovImage from "../../assets/SlickTeam/YuriiKravtsov.jpg";
import VitaliiKondatiukImage from "../../assets/SlickTeam/VitaliiKondatiuk.jpg";
import BohdanPasichenkoImage from "../../assets/SlickTeam/BohdanPasichenko.jpg"

const FoundersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  // align-items: center;
  justify-content: flex-start;
`;

const FoundersTextContainer = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const FoundersTitle = styled.div`
  font-size: 20px;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;
  // text-align: center;
  color: ${theme.colors.white};
`;

const FoundersTitleBiography = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;
  color: ${theme.colors.grey};
`;

const FoundersLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const StyledImgSection3 = styled.img`
  max-width: 150px;
  width: 100%;
  opacity: 1;
  height: 150px;
  object-fit: cover;
  border-radius: 150px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.hoverColor || theme.colors.white} !important;
  }
`;

export const FoundersData = [
  {
    image: VitaliiKulykImage,
    name: "Віталій Кулик",
    // biography: "Коментатор",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/vitalikkulyk",
      },
    ],
  },

  {
    image: OrestHutsulImage,
    name: "Орест Гутцул",
    // biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/oresthutsul",
      },
      {
        icon: faTwitch,
        color: theme.colors.green,
        hoverColor: "#6441a5",
        link: "https://www.twitch.tv/lazyracing",
      },
      {
        icon: faTiktok,
        color: theme.colors.green,
        hoverColor: "#ff0050",
        link: "https://www.tiktok.com/@lazyracing",
      },
      // {
      //   icon: faYoutube,
      //   color: theme.colors.green,
      //   hoverColor: "#ff0000",
      //   link: "https://www.youtube.com/@lazy_racing",
      // },
    ],
  },
  {
    image: VitaliiKondatiukImage,
    name: "Віталій Кондратюк",
    // biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/vitalchik77",
      },
    ],
  },
  {
    image: IgorDubokImage,
    name: "Ігор Дубок",
    // biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/b4r0nnnnnnn/",
      },
    ],
  },
  {
    image: DmytroHorbanImage,
    name: "Дмитро Горбань",
    // biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/takeuchuha/",
      },
      {
        icon: faTwitch,
        color: theme.colors.green,
        hoverColor: "#6441a5",
        link: "https://www.twitch.tv/takeuchuha",
      },
      {
        icon: faYoutube,
        color: theme.colors.green,
        hoverColor: "#ff0000",
        link: "https://www.youtube.com/@takeuchuwa",
      },
    ],
  },
  {
    image: YuriiKravtsovImage,
    name: "Юрій Кравцов",
    // biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/yurii_kravtsov",
      },
      {
        icon: faTwitch,
        color: theme.colors.green,
        hoverColor: "#6441a5",
        link: "https://www.twitch.tv/seeminglone1",
      },
    ],
  },
  {
    image: BohdanPasichenkoImage,
    name: "Богдан Пасіченко",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/pas1k92",
      }
    ],
  },
];

export const renderFounders = () => {
  return FoundersData.map((founder, index) => (
    <FoundersContainer key={index}>
      <StyledImgSection3 src={founder.image} />
      <FoundersTextContainer>
        <FoundersTitle>{founder.name}</FoundersTitle>
        <FoundersTitleBiography>{founder.biography}</FoundersTitleBiography>

        <FoundersLinks>
          {founder.socialMedia.map((social, i) => (
            <a key={i} href={social.link}>
              <StyledFontAwesomeIcon
                icon={social.icon}
                color={social.color}
                hoverColor={social.hoverColor}
              />
            </a>
          ))}
        </FoundersLinks>
      </FoundersTextContainer>
    </FoundersContainer>
  ));
};
