import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Chip } from "@mui/material";

import Link from "../Common/Link";
import theme from "../../styles/theme";
import {
  getRelationItem,
  getPostImageUrl,
  POST_IMAGE_SIZES,
} from "../../utils/helpers/generic";

const PostContainer = styled.div``;

const PostTitleContainer = styled.div``;

const PostTitle = styled.div`
  margin-bottom: 16px;
  line-height: normal;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
`;

const PostSubtext = styled.div`
  color: ${theme.colors.grey};
`;

const PostTagsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const MainImage = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  margin-bottom: 16px;

  margin-left: -16px;
  margin-top: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
`;

const PostMainImageContainer = styled.div`
  background: ${theme.colors.darkLight};
  padding: 16px;
  border-radius: 4px;
  margin-top: 24px;
`;

const PostText = styled.div`
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  margin-top: 0px;

  font-weight: 300;

  & a {
    color: yellow;
  }

  & p {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  & figure {
    margin-block-start: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-end: 0;
  }

  & blockquote {
    margin: 0 0 25px 12px;
    border-left: 2px solid ${theme.colors.grey};
    padding: 25px;
    background: ${theme.colors.grey}20;
    border-radius: 4px;
  }

  & li {
    font-size: 1rem;
  }

  & blockquote > p {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0px;
  }

  & figure.image.image_resized {
    margin: auto;

    @media (max-width: ${theme.breakpointsCustom.sm}px) {
      width: 100% !important;
    }
  }

  & img {
    height: auto;
    border-radius: 4px;
    max-width: 100%;
  }

  & video {
    margin-left: -16px;
    margin-top: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  & hr {
    opacity: 5%;
  }
`;

export const Post = (post) => {
  const tags = getRelationItem(post, "tags");
  const category = getRelationItem(post, "category");
  const imageUrl = getPostImageUrl(post, POST_IMAGE_SIZES.large);
  const { title, publishedAt, textBlock } = post;
  const date = moment(publishedAt).format("LLL");

  const isMultipleAuthors = post.author && post.author.indexOf(",") > -1;

  return (
    <PostContainer style={{}}>
      <PostTitleContainer>
        <PostTitle>{title}</PostTitle>
        <PostSubtext>
          {isMultipleAuthors ? "Автори" : "Автор"}: {post.author}
        </PostSubtext>
        <PostSubtext>Опубліковано: {date}</PostSubtext>
        <PostSubtext style={{ marginTop: 8 }}>
          Категорія:
          <Link
            to={`/news?${new URLSearchParams({ categories: [category.id] })}`}
          >
            <Chip
              clickable
              label={category.name}
              size="small"
              style={{ margin: `0 8px` }}
            />
          </Link>
        </PostSubtext>

        <PostTagsContainer>
          <PostSubtext>Теги:</PostSubtext>
          {tags.map((tag, index) => {
            const searchParams = new URLSearchParams({ tags: [tag.id] });
            const link = `/news?${searchParams}`;
            return (
              <Link to={link}>
                <Chip clickable label={tag.name} size="small" />
              </Link>
            );
          })}
        </PostTagsContainer>
      </PostTitleContainer>
      <PostMainImageContainer>
        {post.showMainImage && <MainImage src={imageUrl} />}

        <PostText
          dangerouslySetInnerHTML={{
            __html: textBlock,
          }}
        />
      </PostMainImageContainer>
    </PostContainer>
  );
};
