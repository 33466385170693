import React, { createContext, useContext, useState } from "react";

import { getHighlightsPlaylistId } from "../constants/youtube";
import { getPlaylistVideos } from "../utils/googleApi/youtube";

const HighlightsContext = createContext({
  seasonHightlights: {},
});

export const useHighlightsContext = () => {
  return useContext(HighlightsContext);
};

export const HighlightsProvider = ({ children }) => {
  const [seasonHightlights, setSeasonHightlights] = useState({});

  const loadSeasonHighlights = async (seasonId) => {
    if (seasonHightlights[seasonId]) return; //already exists

    const videos = await getPlaylistVideos({
      playlistId: getHighlightsPlaylistId(seasonId),
    });

    setSeasonHightlights((prevValue) => {
      prevValue[seasonId] = videos;
      return { ...prevValue };
    });
  };

  return (
    <HighlightsContext.Provider
      value={{ seasonHightlights, loadSeasonHighlights }}
    >
      {children}
    </HighlightsContext.Provider>
  );
};
