import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import theme from "../../styles/theme";
import { PostItem } from "../../components/News/NewsItem";
import BlockTitle from "../../components/Common/BlockTitle";
import { useNewsContext } from "../../contexts/news";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


const TopContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.colors.white}`,
      borderWidth: "1px",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: `${theme.colors.white}`,
    },
  },
});

const GroupHeader = styled.div`
  position: sticky;
  top: -8px;
  padding: 4px 10px;
  color: ${theme.colors.white}60;
  background-color: #1e1e1e;
  font-size: 12px;
  font-style: italic;
  text-align: right;
`;

const GroupItems = styled.ul`
  padding: 0;
`;

const News = () => {
  const MAX_TAGS = 3;
  const MAX_CATEGORIES = 2;
  let [searchParams, setSearchParams] = useSearchParams();
  const { posts, filteredPosts, tags, categories, filter, loadPosts } =
    useNewsContext();
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  useEffect(() => {
    const tagsParam = searchParams.get("tags");
    const categoriesParam = searchParams.get("categories");

    if (!tagsParam && !categoriesParam) {
      if (!_.isEmpty(selectedTagIds) || !_.isEmpty(selectedCategoryIds)) {
        setSelectedTagIds([]);
        setSelectedCategoryIds([]);
      }
      return;
    }

    const tagIds = tagsParam?.split(",").map(Number) || [];
    const categoryIds = categoriesParam?.split(",").map(Number) || [];

    if (!_.isEqual(tagIds.sort(), selectedTagIds.sort())) {
      setSelectedTagIds(tagIds);
    }
    if (!_.isEqual(categoryIds.sort(), selectedCategoryIds.sort())) {
      setSelectedCategoryIds(categoryIds);
    }
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    const newFilter = { $and: [] };
    if (selectedTagIds.length) {
      newFilter.$and.push({ tags: { id: { $in: selectedTagIds } } });
    }
    if (selectedCategoryIds.length) {
      newFilter.$and.push({ category: { id: { $in: selectedCategoryIds } } });
    }

    if (newFilter.$and.length === 0) {
      loadPosts({});
    } else {
      loadPosts({ filter: newFilter });
    }
  }, [selectedTagIds, selectedCategoryIds]); // eslint-disable-line

  

  const onFilterChange = useCallback(
    (type) => (event, newValue) => {
      if (type === "tags") {
        const newTags = newValue.map((i) => i.id);

        if (newTags.length && newTags.length <= MAX_TAGS) {
          searchParams.set("tags", newTags);
        } else {
          searchParams.delete("tags");
        }
      }
      if (type === "categories") {
        const newCategories = newValue.map((i) => i.id);

        if (newCategories.length && newCategories.length <= MAX_CATEGORIES) {
          searchParams.set("categories", newCategories);
        } else {
          searchParams.delete("categories");
        }
      }

      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const list = _.isEmpty(filter) ? posts : filteredPosts;

  const sortedTags = _.sortBy(tags, (o) => o.tagGroup.name);
  const sortedCategories = _.sortBy(categories, (o) => o.name);

  return (
    <div style={{ marginTop: 24 }}>
      <BlockTitle>Новини</BlockTitle>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: 24,
          flexWrap: "wrap",
        }}
      >
        <div style={{ maxWidth: "500px", width: "100%" }}>
          <StyledAutocomplete
            multiple
            groupBy={(option) => option.tagGroup.name}
            options={sortedTags || []}
            getOptionLabel={(option) => option.name}
            value={_.filter(tags, (tag) => _.includes(selectedTagIds, tag.id))}
            renderInput={(params) => (
              <TextField {...params} label="Пошук за тегами" />
            )}
            onChange={onFilterChange("tags")}
            renderGroup={(params) => {
              return (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              );
            }}
          />
        </div>
        <div style={{ maxWidth: "500px", width: "100%" }}>
          <StyledAutocomplete
            multiple
            options={sortedCategories || []}
            getOptionLabel={(option) => option.name}
            value={_.filter(categories, (category) =>
              _.includes(selectedCategoryIds, category.id)
            )}
            renderInput={(params) => (
              <TextField {...params} label="Пошук за категоріями" />
            )}
            onChange={onFilterChange("categories")}
          />
        </div>
      </div>
      <TopContainer>
        {list.map((post) => (
          <PostItem key={post.id} {...post} />
        ))}
      </TopContainer>
    </div>
  );
};

export default News;
