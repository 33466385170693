import React from "react";
import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { default as driver1 } from "../../assets/rewards/reward-driver-1.png";
import { default as driver2 } from "../../assets/rewards/reward-driver-2.png";
import { default as driver3 } from "../../assets/rewards/reward-driver-3.png";
import { default as team1 } from "../../assets/rewards/reward-team-1.png";
import { default as team2 } from "../../assets/rewards/reward-team-2.png";
import { default as team3 } from "../../assets/rewards/reward-team-3.png";

const rewardImages = {
  driver1,
  driver2,
  driver3,
  team1,
  team2,
  team3,
};

const TrophyContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
`;

const Trophy = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  flex-direction: row;
  gap: 3px;
  padding-bottom: 8px;

  & p {
    margin: 0;
  }
`;

const TrophyCounter = styled.div`
  color: #fff5f580;
  font-size: 16px;
`;

const AchievementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 8px;
`;

const StyledIcon = styled.img`
  width: 26px;
`;

const TooltipContainer = styled.div`
  display: flex;
  gap: 4px;
  & > span {
    color: white;
  }
`;

const Achievements = ({ achievements }) => {
  const countAchievementOccurrences = () => {
    const occurrenceMap = {};
    achievements.forEach((achievement) => {
      occurrenceMap[achievement.image] =
        (occurrenceMap[achievement.image] || 0) + 1;
    });
    return occurrenceMap;
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  const occurrenceMap = countAchievementOccurrences();
  return (
    <TrophyContainer>
      {achievements.map(({ image, place, name }, index) => {
        const count = occurrenceMap[image];
        if (
          (index === 0 || image !== achievements[index - 1].image) &&
          (count > 1 ||
            index === 0 ||
            place !== achievements[index - 1].place) &&
          name !== "Топ 10"
        ) {
          const tooltipContent = name;
          const seasons = achievements
            .filter((achievement) => achievement.image === image)
            .map((achievement) => `${achievement.at}`);
          return (
            <Trophy key={index}>
              <NoMaxWidthTooltip
                title={
                  <TooltipContainer>
                    {tooltipContent}
                    <span>-</span>
                    <span> {seasons.join(", ")}</span>
                  </TooltipContainer>
                }
                placement="top-start"
              >
                <StyledIcon src={rewardImages[image]} alt="Icon" />
              </NoMaxWidthTooltip>
              {count > 1 ? <TrophyCounter>x{count}</TrophyCounter> : null}
            </Trophy>
          );
        }
        return null;
      })}
    </TrophyContainer>
  );
};

const DriverAchievements = ({
  driversAchievements,
  constructorAchievements,
}) => {
  return (
    <AchievementsContainer>
      <Achievements achievements={driversAchievements} />
      <Achievements achievements={constructorAchievements} />
    </AchievementsContainer>
  );
};

export default DriverAchievements;
