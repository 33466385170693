import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../styles/theme";
import {
  RaceResultsRow,
  QualiResultsRow,
  EmptyRowQ1,
  EmptyRowQ2,
} from "./RaceResultsRow";
import msToTime from "../../utils/helpers/msToTime";

import _ from "lodash";

const StandingsContainer = styled.div`
  position: relative;
  font-family: "Montserrat", sans-serif;
  // padding: 4px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  user-select: none;
`;

const StyledTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    color: ${theme.colors.grey};
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    white-space: nowrap;

    text-align: left;
    border-bottom: none;
  }
`;

const StyledTable = styled(Table)`
  margin: auto;
  min-width: 360px !important;
  background-color: ${theme.colors.dark};
  border-radius: 0px;

  & > tbody > tr > td {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;

    color: ${theme.colors.white};
    border-bottom: none;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  z-index: 3;
`;

const NoData = styled.div`
  text-align: center;
  opacity: 50%;
  text-transform: uppercase;
  margin-top: 30px;
`;

const raceTypes = {
  Qualification: "qualiResults",
  Sprint: "sprintResults",
  Race: "raceResults",
};

const formatQualiResults = (race) => {
  if (!race?.qualiResults?.length) return;

  _.forEach(race.qualiResults, (qualiResult, index) => {
    const { status, fastestLap } = qualiResult;
    const dnf = status === "DNF";
    const dsq = status === "DSQ";
    const hasTime = !dsq && !dnf;

    if (!hasTime) {
      if (dnf) qualiResult.timeText = "DNF";
      if (dsq) qualiResult.timeText = "DSQ";
      return;
    }
    qualiResult.timeText = `${msToTime(fastestLap?.time)}`;
    return;
  });
};

export function RaceResultsTable({ race, type, minFastestLapTime, leaderGap }) {
  const firstRow = race[raceTypes[type]][0];

  return (
    <Fragment>
      <StandingsContainer>
        <StyledTableContainer>
          <StyledTable sx={{}}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{ width: "10px", padding: "16px 4px 16px 16px" }}
                >
                  Поз
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: "40px", padding: "16px 4px 16px 4px" }}
                ></StyledTableCell>

                <StyledTableCell style={{ width: "280px" }}>
                  Пілот
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    "@media (max-width: 480px)": {
                      display: "none",
                    },
                    width: "200px",
                  }}
                >
                  Команда
                </StyledTableCell>

                <StyledTableCell style={{ width: "100px" }}>
                  Час
                </StyledTableCell>

                <StyledTableCell style={{ width: "60px" }}>
                  Штраф
                </StyledTableCell>

                {firstRow.stints && (
                  <StyledTableCell style={{ width: "280px" }}>
                    Гума
                  </StyledTableCell>
                )}

                <StyledTableCell style={{ width: "200px" }}>
                  Найшвидше Коло
                </StyledTableCell>

                <StyledTableCell style={{ width: "40px" }}>
                  ОЧКИ
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {race[raceTypes[type]]
                .filter((row, i) => !(row.status === "DNS"))
                .map((row, i) => {
                  return (
                    <RaceResultsRow
                      key={row.number}
                      {...row}
                      leaderGap={leaderGap}
                      isFastestLap={row.fastestLap?.time === minFastestLapTime}
                    />
                  );
                })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </StandingsContainer>
    </Fragment>
  );
}

export function QualificationResultsTable({ race }) {
  const qualiType = race.details.qualiType;
  return (
    <Fragment>
      <StandingsContainer>
        <StyledTableContainer>
          <StyledTable sx={{}}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{ width: "10px", padding: "16px 4px 16px 16px" }}
                >
                  Поз
                </StyledTableCell>
                <StyledTableCell>Пілот</StyledTableCell>
                <StyledTableCell
                  sx={{
                    "@media (max-width: 480px)": {
                      display: "none",
                    },
                    width: "500px",
                  }}
                >
                  Команда
                </StyledTableCell>

                <StyledTableCell>Час</StyledTableCell>

                <StyledTableCell style={{ width: "40px" }}>
                  Гума
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {race[raceTypes["Qualification"]].map((row, i) => {
                return (
                  <Fragment>
                    {i === 10 && <EmptyRowQ2 qualiType={qualiType} />}
                    {i === 15 && <EmptyRowQ1 qualiType={qualiType} />}
                    <QualiResultsRow key={row.number} {...row} />
                  </Fragment>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </StandingsContainer>
    </Fragment>
  );
}

export function RaceResults({ type, race }) {
  const [leaderGap, setLeaderGap] = useState(null);
  const [minFastestLapTime, setMinFastestLapTime] = useState(Infinity);

  const isQualification = type === "Qualification";

  useEffect(() => {
    formatQualiResults(race);

    if (race && race.raceResults) {
      const fastestLapTimes = race.raceResults.map((result) =>
        result.fastestLap ? result.fastestLap.time : Infinity
      );
      const minTime = Math.min(...fastestLapTimes);

      setMinFastestLapTime(minTime);
      setLeaderGap(race.raceResults[0]?.gap);
    }
  }, [race]);

  if (!race || !race[raceTypes[type]]) {
    return <NoData>No data </NoData>;
  }

  if (isQualification) return <QualificationResultsTable race={race} />;

  const props = { race, leaderGap, minFastestLapTime, type };
  return <RaceResultsTable {...props} />;
}
