import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import { getRelationItem } from "../utils/helpers/generic";
import { axiosCMSInstance } from "../utils/axios";

const NewsContext = createContext({
  posts: [],
  postsMap: {},
});

export const useNewsContext = () => {
  return useContext(NewsContext);
};

export const NewsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [filter, setFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postsMap, setPostsMap] = useState({});
  //eslint-disable-next-line
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 50,
    pageCount: 0,
    total: 0,
  });

  useEffect(() => {
    const obj = {};
    posts.forEach((item) => {
      obj[item.id] = item;
    });
    setPostsMap({ ...postsMap, ...obj });
  }, [posts]); //eslint-disable-line

  const loadPost = useCallback(async (id) => {
    const response = await axiosCMSInstance.get(`/posts/${id}`, {
      params: {
        populate: "*",
      },
    });
    //eslint-disable-next-line
    const { data } = response.data;
    const post = {
      id: data.id,
      ...data.attributes,
    };

    setPostsMap({ ...postsMap, ...{ [post.id]: post } });
  }, []); //eslint-disable-line

  const loadPosts = useCallback(async ({ filter = {} }) => {
    try {
      const response = await axiosCMSInstance.get("/posts", {
        params: {
          populate: "*",
          pagination: {
            page: 1,
            pageSize: 50,
          },
          filters: filter,
          sort: { publishedAt: "desc" },
        },
      });

      const { data, meta } = response.data;

      const posts = data.map((item) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      if (_.isEmpty(filter)) {
        setPagination(meta.pagination);
        setPosts(posts);
        setFilteredPosts([]);
        setFilter({});
      } else {
        setFilteredPosts(posts);
        setFilter(filter);
      }
    } catch (error) {
      console.error("Error loading posts:", error);
    }
  }, []);

  useEffect(() => {
    const loadTags = async () => {
      const response = await axiosCMSInstance.get("/tags", {
        params: {
          populate: "*",
          fields: ["id", "name"],
          pagination: {
            page: 1,
            pageSize: 200,
          },
        },
      });

      const { data } = response.data;
      const tags = data.map((item) => ({
        id: item.id,
        ...item.attributes,
        tagGroup: {
          ...getRelationItem(item.attributes, "tagGroup"),
        },
      }));

      setTags(tags);
    };

    const loadCategories = async () => {
      const response = await axiosCMSInstance.get("/categories", {
        params: {
          populate: "*",
          fields: ["id", "name"],
          pagination: {
            page: 1,
            pageSize: 200,
          },
        },
      });

      const { data } = response.data;
      const categories = data.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));

      setCategories(categories);
    };

    loadTags();
    loadCategories();
  }, []);

  return (
    <NewsContext.Provider
      value={{
        posts,
        filteredPosts,
        filter,
        tags,
        categories,
        postsMap,
        loadPost,
        loadPosts,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
